import {zodResolver} from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {useState, Dispatch, SetStateAction, useEffect} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import Button from '~/components/Button';
import FormInput from '~/components/FormInput';
import LinearLoading from '~/components/LineLoader';
import {OrgDetails, useOrgs} from '~/hooks/queries/organizations';
import {useCreateUser} from '~/hooks/queries/users';
import useBreakpoints from '~/hooks/useBreakpoints';
import {authStore, Role} from '~/state/store';
import {AddUserSchema, UserCreateType} from '~/types/zod_objects';

type GeneralAddUserDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  org_id?: string;
};

const GeneralAddUserDialog = ({open, setOpen, org_id}: GeneralAddUserDialogProps) => {
  const [loading, setLoading] = useState(false);
  const breakpoints = useBreakpoints();
  const postUser = useCreateUser();
  const {superUser, org_id: own_org_id} = authStore();
  const {data: listOfOrgs} = useOrgs();
  const params = useParams();
  const org_id_parameter = params.orgId;

  const default_organization_id = org_id_parameter ? org_id_parameter : own_org_id || '';

  useEffect(() => {
    listOfOrgs?.push({
      id: '',
      name: 'Ingen organisation',
      address: '',
      created_at: '',
      cvr: '',
      is_verified: false,
      updated_at: '',
      website: '',
      zip: '',
      city: '',
      country: '',
    });
  }, [listOfOrgs]);

  const formMethods = useForm<UserCreateType>({
    resolver: zodResolver(AddUserSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      org_role: Role.USER,
      organization_id: default_organization_id,
    },
  });

  const {handleSubmit, control, setValue, watch, reset} = formMethods;

  const handleCreate = async (values: UserCreateType) => {
    setLoading(true);

    // if everything is fine :
    postUser.mutateAsync(values, {
      onSuccess: () => {
        setLoading(false);
        setOpen(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  let ownOrgName = listOfOrgs?.find((org: OrgDetails) => org.id === org_id)?.name;
  if (!ownOrgName) {
    ownOrgName = 'Ingen organisation';
  }
  /* const listOfOrgInput =
    superUser && !isLoading ? (
      listOfOrgs?.map((org: any) => (
        <MenuItem key={org.id} value={org.id}>
          {org.name}
        </MenuItem>
      ))
    ) : (
      <MenuItem key={org_id}>{ownOrgName}</MenuItem>
    ); */

  const selectedOrg = watch('organization_id');

  const selectedOrgObject = listOfOrgs?.find((org) => org.id === selectedOrg);

  if (!listOfOrgs) {
    return null;
  }

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
          paddingBottom: 1,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <DialogTitle>Tilføj bruger til organisation</DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <DialogContentText mb={2}>
          For at oprette en bruger bedes du udfylde nedenstående felter.
        </DialogContentText>
        <FormProvider {...formMethods}>
          <FormInput
            name="first_name"
            label="Fornavn"
            fullWidth
            sx={{
              mb: 2,
            }}
          />
          <FormInput
            name="last_name"
            label="Efternavn"
            fullWidth
            sx={{
              mb: 2,
            }}
          />
          <FormInput
            name="email"
            label="E-mail"
            required
            fullWidth
            sx={{
              mb: 2,
            }}
          />
          <FormControl sx={{mb: 2}} fullWidth>
            <InputLabel id="role-label">Rettighed</InputLabel>
            <Select
              id="org_role"
              labelId="role-label"
              label="Rettighed"
              defaultValue={Role.USER}
              {...formMethods.register('org_role')}
            >
              <MenuItem value={Role.USER}>Bruger</MenuItem>
              <MenuItem value={Role.ADMIN}>Administrator</MenuItem>
            </Select>
          </FormControl>
          <Controller
            name="organization_id"
            control={control}
            render={({field}) => (
              <Autocomplete
                {...field}
                options={listOfOrgs}
                getOptionLabel={(option) => option.name}
                defaultValue={listOfOrgs[0]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Organisation"
                    required
                    fullWidth
                    sx={{mb: 2}}
                    disabled={!superUser}
                  />
                )}
                onChange={(_event, value) => {
                  setValue('organization_id', value?.id || '');
                }}
                isOptionEqualToValue={(option, value) =>
                  value !== undefined ? option.id === value.id : true
                }
                value={selectedOrgObject}
              />
            )}
          />
          {/* <FormControl sx={{mb: 2}} fullWidth>
            <InputLabel id="organization-label">Organisation</InputLabel>
            <Select
              id="org_id"
              labelId="organization-label"
              required
              label="Organisation"
              {...formMethods.register('org_id')}
              defaultValue={'Ingen organisation'}
            >
              {listOfOrgInput}
            </Select>
          </FormControl> */}
          <Box sx={{display: 'flex', justifyContent: 'center'}}></Box>
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{paddingRight: 3}}>
        <Button bttype="tertiary" onClick={handleClose}>
          Annuller
        </Button>

        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          {!loading ? (
            <Button size="medium" bttype="primary" onClick={handleSubmit(handleCreate)}>
              {breakpoints.isMobile ? 'Opret' : 'Opret bruger'}
            </Button>
          ) : (
            <LinearLoading width="large" height="medium" color="primary" />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default GeneralAddUserDialog;
