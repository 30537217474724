import {Box} from '@mui/material';
import {useState} from 'react';

import EditOrganizationDialog from '~/components/dialogs/EditOrganizationDialog';

import OrganizationCarousel, {OrganizationDetailsProps} from './OrganizationCarousel';

const OrganizationDetails = ({projects, units, users, org_data}: OrganizationDetailsProps) => {
  const [editDialog, setEditDialog] = useState(false);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '145px',
        marginRight: '1em',
      }}
    >
      <EditOrganizationDialog open={editDialog} setOpen={setEditDialog} organization={org_data} />
      <Box
        className="Carousel"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          maxWidth: '1480px',
        }}
      >
        <OrganizationCarousel projects={projects} units={units} users={users} org_data={org_data} />
      </Box>

      {/* <Paper
        className="Virksomhedsinfo"
        sx={{marginBottom: '1em', width: '100%', height: '145px', marginRight: 0}}
        elevation={0}
      >
        <Box
          sx={{
            width: '100%',
            paddingX: 4,
            display: 'flex',
            height: '145px',
            justifyContent: 'space-around',
          }}
        >
          {org_data != undefined ? (
            <>
              <CardContent
                sx={{
                  display: 'flex',
                  width: '25%',
                  minWidth: '250px',
                  flexDirection: 'column',
                  alignItems: 'left',
                  paddingY: 0,
                  justifyContent: 'start',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 900,
                    borderBottom: '1px solid rgb(0, 120, 109);',
                    marginBottom: 2,
                    paddingBottom: 0.5,
                    textAlign: 'center',
                  }}
                >
                  Calypso
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 0.25,
                  }}
                >
                  <Typography variant="subtitle2">Antal Brugere:</Typography>
                  <Typography variant="subtitle2">{users?.length}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 0.25,
                  }}
                >
                  <Typography variant="subtitle2">Antal Projekter:</Typography>
                  <Typography variant="subtitle2">{projects?.length}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 0.25,
                  }}
                >
                  <Typography variant="subtitle2"> Antal Udstyr:</Typography>
                  <Typography variant="subtitle2"> {units?.length}</Typography>
                </Box>
              </CardContent>

              <Divider orientation="vertical" sx={{marginLeft: '0em', my: '1em'}} flexItem />
              <CardContent
                sx={{
                  display: 'flex',
                  width: '75%',
                  minWidth: '210px',
                  paddingBottom: '0px !important',
                  paddingTop: '0px !important',
                  paddingX: 2,
                  flexDirection: 'column',
                  alignItems: 'left',
                  justifyContent: 'start',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 900,
                    borderBottom: '1px solid rgb(0, 120, 109);',
                    marginBottom: 2,
                    paddingBottom: 0.5,
                    textAlign: 'center',
                  }}
                >
                  Virksomhedsinfo
                </Typography>
                <Box sx={{display: 'flex'}}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '50%',
                      minWidth: '210px',
                      flexDirection: 'column',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 0.25,
                      }}
                    >
                      <Typography variant="subtitle2" pr={5}>
                        {' '}
                        Addresse:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                      >
                        {' '}
                        {org_data?.address}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 0.25,
                      }}
                    >
                      <Typography variant="subtitle2"> Postnummer:</Typography>
                      <Typography variant="subtitle2"> {org_data?.zip}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 0.25,
                      }}
                    >
                      <Typography variant="subtitle2"> By: </Typography>
                      <Typography variant="subtitle2"> {org_data?.city}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 0.25,
                      }}
                    >
                      <Typography variant="subtitle2"> Land: </Typography>
                      <Typography variant="subtitle2"> {org_data?.country}</Typography>
                    </Box>
                  </Box>
                  <Divider orientation="vertical" sx={{marginX: '2em', my: '0em'}} flexItem />

                  <Box
                    sx={{
                      display: 'flex',
                      width: '50%',
                      minWidth: '210px',
                      flexDirection: 'column',
                      alignItems: 'left',
                      justifyContent: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 0.25,
                      }}
                    >
                      <Typography variant="subtitle2"> CVR</Typography>
                      <Typography variant="subtitle2"> {org_data?.cvr}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 0.75,
                      }}
                    >
                      <Typography variant="subtitle2"> Hjemmeside:</Typography>
                      <Link href={`https://${org_data?.website}`} target="_blank" rel="noreferrer">
                        <Typography variant="subtitle2">
                          {org_data?.website ?? 'Ikke angivet'}
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                      {editPermissions && (
                        <Button
                          bttype="primary"
                          onClick={() => navigate(`/organizations/${viewed_org}/edit`)}
                          startIcon={<Edit />}
                        >
                          Rediger
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </>
          ) : (
            <LinearLoading color="primary" />
          )}
        </Box>
      </Paper> */}
    </Box>
  );
};

export default OrganizationDetails;
