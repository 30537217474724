import {zodResolver} from '@hookform/resolvers/zod';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Typography,
  InputAdornment,
} from '@mui/material';
import {useState} from 'react';
import {useForm, FormProvider, useWatch} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import * as z from 'zod';

import {verifyDomainAvailability} from '~/api/functions';
import Button from '~/components/Button';
import FormInput from '~/components/FormInput';
import LinearLoading from '~/components/LineLoader';
import {useCreateDomain} from '~/hooks/queries/organizations';

type EditVerifiedDomainDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  baseDomain: string;
};

export const EditVerifiedDomainDialog = ({
  open,
  setOpen,
  baseDomain,
}: EditVerifiedDomainDialogProps) => {
  const [state, setState] = useState<'idle' | 'loading' | 'success' | 'error' | 'empty'>('idle');
  const [validDomain, setValidDomain] = useState<string>('');
  const [previouslyCheckedDomain, setPreviouslyCheckedDomain] = useState<string>('');

  const createDomainMutation = useCreateDomain();

  const formMethods = useForm({
    defaultValues: {
      domain: baseDomain,
    },
    resolver: zodResolver(
      z.object({
        domain: z.string(),
      })
    ),
  });
  const {handleSubmit, control} = formMethods;
  const watchedDomain = useWatch({control, name: 'domain'});
  const hideAcceptButton = watchedDomain !== validDomain || !validDomain;
  const params = useParams();

  const orgId = params.orgId;

  const handleDomainCreation = () => {
    if (validDomain === '') return;

    createDomainMutation.mutate({domain: validDomain, org_id: orgId as string});
    setOpen(false);
  };

  const onSubmit = (data: {domain: string}) => {
    if (data.domain === '') {
      formMethods.setError('domain', {message: ''});
      setState('empty');
      return;
    }

    setState('loading');
    verifyDomainAvailability(data.domain)
      .then((response) => {
        if (response) {
          setState('success');
          setPreviouslyCheckedDomain(data.domain);
          setValidDomain(data.domain);
        } else {
          formMethods.setError('domain', {message: ''});
          setState('error');
          setPreviouslyCheckedDomain(data.domain);
        }
      })
      .catch(() => {
        formMethods.setError('domain', {message: ''});
        setState('error');
        setPreviouslyCheckedDomain(data.domain);
      });
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 4,
            padding: 1,
            paddingBottom: 0,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          },
        }}
      >
        <DialogTitle>Tilføj domænebeskyttelse til din organisation</DialogTitle>
        <DialogContent sx={{paddingBottom: 0}}>
          <Typography variant="body2" mb={2}>
            Domænebeskyttelse giver din organisation eksklusiv rettighed til at bruge email-domænet.
            <br></br>
            Det vil forhindre andre i at bruge email-domænet til at oprette en organisation i
            Calypso.
            <br></br>
            Det vil samtidigt gøre det nemt at invitere brugere til din organisation.
          </Typography>
          <DialogContentText mb={1}>Indtast dit email-domæne</DialogContentText>

          <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
            <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
              <FormInput
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailIcon sx={{height: 20}} />
                    </InputAdornment>
                  ),
                }}
                name="domain"
                label="Email domæne"
                required
                fullWidth
              />
            </Box>
            <Button
              sx={{mt: 1, width: '90px', height: '45px'}}
              bttype="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {state == 'loading' ? <LinearLoading /> : 'Verificer'}
            </Button>
          </Box>
          {state == 'success' && !hideAcceptButton && (
            <Typography mt={0} color="green" variant="body2">
              Dette domæne er tilgængeligt
            </Typography>
          )}
          {state == 'error' && watchedDomain === previouslyCheckedDomain && (
            <Typography mt={0} color="red" variant="body2">
              Dette domæne er ikke tilladt
            </Typography>
          )}
          {(state === 'idle' ||
            state === 'loading' ||
            (state == 'success' && hideAcceptButton) ||
            (state == 'error' && watchedDomain !== previouslyCheckedDomain)) && (
            <Typography mt={0} color="gray" variant="body2">
              &nbsp;
            </Typography>
          )}
          {state === 'empty' && (
            <Typography mt={0} color="red" variant="body2">
              Dette felt må ikke være tomt
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{marginRight: 0}}>
          <Button onClick={() => setOpen(false)} bttype="tertiary">
            Annuller
          </Button>
          <Box sx={{display: 'flex', width: 91, justifyContent: 'center'}}>
            <Button disabled={hideAcceptButton} onClick={handleDomainCreation} bttype="primary">
              Accepter
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
