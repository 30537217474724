import {Box, Container, Typography} from '@mui/material';
import {MRT_ColumnDef, MRT_Row, MRT_TableOptions, MaterialReactTable} from 'material-react-table';
import moment from 'moment';
import {useMemo} from 'react';

import useBreakpoints from '~/hooks/useBreakpoints';
import {TableTypes, useTable} from '~/hooks/useTable';
import {useStatefullTableAtom} from '~/hooks/useTableState';
import {Unit} from '~/types/zod_objects';
// import {authStore} from '~/state/store';

type UnitsTableProps = {
  data: Unit[] | undefined;
  table_type: TableTypes;
  long?: boolean;
};
const UnitsTable = ({data, table_type, long}: UnitsTableProps) => {
  // const {adminAccess, superUser} = authStore();
  const {isMobile} = useBreakpoints();
  const tableHeight = isMobile
    ? table_type == TableTypes.TABLE
      ? 'calc(100vh - 125px)'
      : 'calc(100vh - 350px)'
    : table_type == TableTypes.TABLE
      ? 'calc(100vh - 150px)'
      : long
        ? 'calc(100vh - 150px)'
        : 'calc(100vh - 350px)';
  const columns = useMemo<MRT_ColumnDef<Unit>[]>(
    () => [
      {
        accessorKey: 'projectno',
        size: 0,
        header: 'Projekt nr.',
      },
      {
        id: 'project_title',
        accessorFn: (row) => row.project_title ?? '',
        size: 0,
        header: 'Projekt Titel',
        Cell: ({cell}) => {
          const globalFilter = table.getState().globalFilter?.toLowerCase();
          const value = cell.getValue<string>().toLowerCase();
          const isHighlighted = globalFilter && value.includes(globalFilter);
          return (
            <Typography
              sx={{
                fontSize: '0.875rem',
                backgroundColor: isHighlighted ? 'rgb(255, 203, 127)' : 'transparent',
              }}
            >
              {cell.getValue<string>()}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'terminal_id',
        header: 'Terminal ID',
        size: 0,
        Cell: ({cell}) => {
          const globalFilter = table.getState().globalFilter?.toLowerCase();
          const value = cell.getValue<string>().toLowerCase();
          const isHighlighted = globalFilter && value.includes(globalFilter);

          return (
            <Typography
              sx={{
                fontSize: '0.875rem',
                backgroundColor: isHighlighted ? 'rgb(255, 203, 127)' : 'transparent',
              }}
            >
              {cell.getValue<string>()}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'loc_name',
        header: 'Lokation',
        size: 100,
        Cell: ({cell}) => {
          const globalFilter = table.getState().globalFilter?.toLowerCase();
          const value = cell.getValue<string>()?.toLowerCase();
          if (!value) return null;
          const isHighlighted = globalFilter && value.includes(globalFilter);
          return (
            <Typography
              sx={{
                fontSize: '0.875rem',
                backgroundColor: isHighlighted ? 'rgb(255, 203, 127)' : 'transparent',
              }}
            >
              {cell.getValue<string>()}
            </Typography>
          );
        },
      },
      {
        id: 'ts_data',
        accessorFn: (row) => {
          const ts_data = row.ts_data || [];
          const ts_logger = row.ts_logtype || [];
          return ts_data
            .map((value, index) => `${value} (${ts_logger[index] || 'Ukendt'})`)
            .join(', ');
        },
        header: 'Data',
        Cell: ({row}) => {
          const ts_data = row.original.ts_data || [];
          const ts_logger = row.original.ts_logtype || [];
          const globalFilter = table.getState().globalFilter?.toLowerCase();

          return (
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, width: `340px`}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between', minWidth: '200px'}}>
                <Typography sx={{fontSize: '0.875rem', width: '50%', fontWeight: 'bold'}}>
                  Data
                </Typography>
                <Typography sx={{fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold'}}>
                  Logger
                </Typography>
              </Box>
              {ts_data.map((value: string, index: number) => {
                const isDataHighlighted =
                  globalFilter && value.toLowerCase().includes(globalFilter);
                const isLoggerHighlighted =
                  globalFilter && ts_logger[index]?.toLowerCase().includes(globalFilter);
                return (
                  <Box
                    key={value + index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      minWidth: '200px',
                    }}
                  >
                    <Typography
                      key={`data-${index}`}
                      sx={{
                        fontSize: '0.875rem',
                        width: '30%',
                        backgroundColor: isDataHighlighted ? 'rgb(255, 203, 127)' : 'transparent',
                      }}
                    >
                      {value}
                    </Typography>
                    <Typography
                      key={`logger-${index}`}
                      sx={{
                        fontSize: '0.875rem',
                        width: '70%',
                        textAlign: 'right',
                        backgroundColor: isLoggerHighlighted ? 'rgb(255, 203, 127)' : 'transparent',
                      }}
                    >
                      {ts_logger[index] || 'Ukendt'}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          );
        },
      },
      {
        accessorKey: 'annual_price',
        header: 'Årlig Pris',
        aggregationFn: 'sum',
        //required to render an aggregated cell, show the average salary in the group
        AggregatedCell: ({cell}) => (
          <>
            Samlet årlig pris:
            <Typography fontWeight={'bold'} color={'#132c9f'} fontSize={'0.875rem'}>
              {cell.getValue<number>().toFixed(0)} kr.
            </Typography>
          </>
        ),
        Cell: ({cell}) => {
          return (
            <Typography fontWeight={'bold'} color={'#132c9f'} fontSize={'0.875rem'}>
              {cell.getValue<number>()} kr.
            </Typography>
          );
        },
      },
      {
        accessorKey: 'startdate',
        header: 'Start Dato',
        Cell: ({cell}) => {
          const globalFilter = table.getState().globalFilter?.toLowerCase();
          const value = moment(cell.getValue<string>()).format('YYYY-MM-DD HH:mm:ss').toLowerCase();
          const isHighlighted = globalFilter && value.includes(globalFilter);
          return (
            <Typography
              sx={{
                backgroundColor: isHighlighted ? 'rgb(255, 203, 127)' : 'transparent',
                fontSize: '0.875rem',
              }}
            >
              {moment(cell.getValue<string>()).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
          );
        },
      },
    ],
    []
  );
  const handleRowClick = (row: MRT_Row<Unit>) => {
    // if row is a grouped column, expand it
    if (row.getIsGrouped()) {
      row.toggleExpanded();
      return;
    }
  };

  const options: Partial<MRT_TableOptions<Unit>> = {
    muiTableBodyRowProps: ({row}) => ({
      onClick: () => {
        handleRowClick(row);
      },
      sx: {
        cursor: 'pointer',
      },
    }),
    enableExpanding: true,
    enableExpandAll: true,
    positionExpandColumn: 'first',
    enableGrouping: true,
    groupedColumnMode: 'reorder',
    paginateExpandedRows: table_type === TableTypes.TABLE ? false : true,
    enableBottomToolbar: table_type === TableTypes.TABLE ? true : false,
    enableColumnDragging: false,
  };
  const [tableState] = useStatefullTableAtom<Unit>('units_table', {
    grouping: ['projectno'],
    columnVisibility: {
      calypso_id: true,
      projectno: true,
      terminal_id: true,
      startdate: true,
      project_info: true,
      project_title: true,
      customer_name: false,
    },
    pagination: {
      pageSize: 15,
      pageIndex: 0,
    },
  });

  const table = useTable<Unit>(columns, data, options, tableState, table_type);

  return (
    <>
      <Container
        sx={{
          my: 0,
          mx: 0,
          paddingRight: '0em !important',
          paddingLeft: '0em !important',
          width: '100%',
          maxWidth: '100% !important',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: tableHeight,
            minHeight: '420px',
          }}
        >
          <MaterialReactTable table={table} />
        </Box>
      </Container>
    </>
  );
};

export default UnitsTable;
