import ArrowForward from '@mui/icons-material/ArrowForward';
import {Fade, Box, Typography} from '@mui/material';
import React from 'react';

import Button from '~/components/Button';

interface LicenseManagementProps {
  page:
    | 'licenseManagement'
    | 'firstIndividualLicense'
    | 'secondIndividualLicense'
    | 'companyLicense';
  setPage: React.Dispatch<
    React.SetStateAction<
      'licenseManagement' | 'firstIndividualLicense' | 'secondIndividualLicense' | 'companyLicense'
    >
  >;
}

const LicenseManagement = ({setPage, page}: LicenseManagementProps) => {
  return (
    <Fade in={page === 'licenseManagement'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
        }}
      >
        <Box>
          <Typography mb={2} textAlign={'center'} variant="h5">
            <b>Licenshåndtering</b>
          </Typography>
        </Box>

        <Box>
          <div>
            <Typography mb={5}>
              Som administrator kan du administrere din organisation&apos; licenser{' '}
            </Typography>
            <Typography mb={3}>
              Der er grundlæggende 2 former for licensstyringer i Calypso:{' '}
            </Typography>
            <Typography>1. Individuel Licensstyring</Typography>
            <Typography>2. Virksomhed Licensstyring</Typography>
          </div>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'center', gap: 2, mt: 4}}>
          <Box sx={{display: 'flex', gap: 2}}>
            <Button
              endIcon={<ArrowForward />}
              onClick={() => setPage('firstIndividualLicense')}
              bttype="primary"
            >
              Fortsæt
            </Button>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default LicenseManagement;
