import PostAddIcon from '@mui/icons-material/PostAdd';
import {Box, Container} from '@mui/material';
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_TableOptions,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
} from 'material-react-table';
import {useMemo, useState} from 'react';

import Button from '~/components/Button';
import CreateProjectDialog from '~/components/dialogs/CreateProjectDialog';
import useBreakpoints from '~/hooks/useBreakpoints';
import {TableTypes, useTable} from '~/hooks/useTable';
import {useStatefullTableAtom} from '~/hooks/useTableState';
import {authStore} from '~/state/store';
import {Project} from '~/types/zod_objects';

type ProjectsTableProps = {
  data: Project[] | undefined;
  table_type: TableTypes;
  long?: boolean;
};

const ProjectsTable = ({data, table_type, long}: ProjectsTableProps) => {
  const {adminAccess, superUser} = authStore();
  const {isMobile} = useBreakpoints();
  const [isCreateProjectOpen, setIsCreateProjectOpen] = useState(false);
  const tableHeight = isMobile
    ? table_type == TableTypes.TABLE
      ? 'calc(100vh - 125px)'
      : 'calc(100vh - 350px)'
    : table_type == TableTypes.TABLE
      ? 'calc(100vh - 150px)'
      : long
        ? 'calc(100vh - 150px)'
        : 'calc(100vh - 350px)';
  const columns = useMemo<MRT_ColumnDef<Project>[]>(
    () => [
      {
        accessorKey: 'project_no',
        // size  : 0,
        header: 'Projekt nr.',
      },
      {
        // accessorKey: 'customer_name',
        id: 'customer_name',
        accessorFn: (row) => row.customer_name ?? '',
        // size: 350,
        header: 'Kunde',
      },
      {
        id: 'is_customer_service',
        accessorFn: (row) => (row.is_customer_service ? 'Ja' : 'Nej'),
        // size: 0,
        header: 'Serviceres af kunde',
        //Cell: ({cell}) => (cell.getValue<boolean>() ? 'Ja' : 'Nej'),
      },
      {
        id: 'project_title',
        accessorFn: (row) => row.project_title ?? '',
        // size: 250,
        header: 'Projekt Titel',
      },
    ],
    []
  );

  const options: Partial<MRT_TableOptions<Project>> = {
    renderTopToolbar: ({table}) => {
      if (!adminAccess && !superUser) return null;

      if (isMobile)
        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              minHeight: '100px',
              mx: 0.5,
            }}
          >
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              {(adminAccess || superUser) && (
                <Button
                  sx={{height: '40px', mr: 0.5}}
                  onClick={() => setIsCreateProjectOpen(true)}
                  bttype="primary"
                  startIcon={<PostAddIcon />}
                >
                  Opret Projekt
                </Button>
              )}
            </Box>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <Box>
                <MRT_GlobalFilterTextField table={table} sx={{width: '200px'}} />
              </Box>
              <Box>
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
              </Box>
            </Box>
          </Box>
        );

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
            margin: 1,
          }}
        >
          <Box sx={{display: 'flex'}}>
            {(adminAccess || superUser) && (
              <>
                <Button
                  sx={{height: '40px', mr: 1, minWidth: '148px'}}
                  onClick={() => setIsCreateProjectOpen(true)}
                  bttype="primary"
                  startIcon={<PostAddIcon />}
                >
                  Opret Projekt
                </Button>
              </>
            )}
          </Box>

          <Box sx={{display: 'flex', gap: 0.5}}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
          </Box>
        </Box>
      );
    },

    muiTableBodyRowProps: () => ({
      sx: {
        cursor: 'pointer',
      },
    }),
    paginateExpandedRows: false,

    enableBottomToolbar: table_type === TableTypes.TABLE ? true : false,
  };

  const [tableState] = useStatefullTableAtom<Project>('projects_table', {});
  const table = useTable<Project>(columns, data, options, tableState, table_type);

  return (
    <>
      <Container
        sx={{
          my: 0,
          mx: 0,
          paddingRight: '0em !important',
          paddingLeft: '0em !important',
          width: '100%',
          maxWidth: '100% !important',
          height: '100%',
        }}
      >
        <CreateProjectDialog open={isCreateProjectOpen} setOpen={setIsCreateProjectOpen} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: tableHeight,
            minHeight: '420px',
          }}
        >
          <MaterialReactTable table={table} />
        </Box>
      </Container>
    </>
  );
};

export default ProjectsTable;
