import {zodResolver} from '@hookform/resolvers/zod';
import {Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';

import Button from '~/components/Button';
import FormInput from '~/components/FormInput';
import {
  useChangePassword,
  ChangePasswordFormValues,
  ChangePasswordSchema,
} from '~/hooks/queries/useLogin';

interface ChangePasswordDialogProps {
  changeOpen: boolean;
  setChangeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePasswordDialog = ({changeOpen, setChangeOpen}: ChangePasswordDialogProps) => {
  const [changeSuccess, setChangeSuccess] = useState(false);
  const mutation = useChangePassword();
  const [searchParams] = useSearchParams();
  const verificationKey = searchParams.get('verification_key') || '';

  const formMethods = useForm<ChangePasswordFormValues>({
    resolver: zodResolver(ChangePasswordSchema),
    defaultValues: {
      password: '',
    },
  });

  const {handleSubmit, reset} = formMethods;

  const handlePasswordChange = async (values: ChangePasswordFormValues) => {
    const redirectKey = searchParams.get('redirect');
    mutation.mutate(
      {
        password: values.password,
        verification_key: `verification_key=${encodeURIComponent(verificationKey)}`,
      },
      {
        onSuccess: () => {
          setChangeSuccess(true);
          setTimeout(() => {
            if (redirectKey) {
              window.location.href = redirectKey;
            } else {
              setChangeOpen(false);
            }
          }, 2500);
        },
        onError: () => {
          setChangeSuccess(false);
        },
      }
    );
  };

  const handleClose = () => {
    setChangeOpen(false);
    reset();
  };

  return (
    <Dialog
      open={changeOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
          paddingBottom: 0,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <FormProvider {...formMethods}>
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5">Angiv ny adgangskode</Typography>
        </DialogTitle>
        <DialogContent>
          {changeSuccess === true ? (
            <Typography>
              Din adgangskode er blevet ændret. Du vil blive sendt til log ind om et øjeblik.
            </Typography>
          ) : (
            <>
              <Typography>Ændre din adgangskode ved at indtaste den i nedenstående felt</Typography>
              <FormInput
                name="password"
                label="Kodeord"
                type={'password'}
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} bttype="tertiary">
            Annuller
          </Button>
          {!changeSuccess && (
            <Button onClick={handleSubmit(handlePasswordChange)} bttype="primary">
              Bekræft
            </Button>
          )}
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default ChangePasswordDialog;
