import {Container, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import Button from '~/components/Button';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container style={{textAlign: 'center', marginTop: '50px'}}>
      <Typography variant="h2" component="h2" gutterBottom>
        404
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        Denne side findes ikke i systemet
      </Typography>
      <Button bttype="primary" onClick={handleGoBack}>
        Gå tilbage
      </Button>
    </Container>
  );
};

export default NotFound;
