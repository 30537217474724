import {Box, Container, Dialog} from '@mui/material';
import React, {useState, useRef} from 'react';

import {appBarHeight} from '~/types/consts';

import CompanyLicense from '../../pages/licences/introductionPages/CompanyLicense';
import FirstIndividualLicense from '../../pages/licences/introductionPages/FirstIndividualLicense';
import LicenseManagement from '../../pages/licences/introductionPages/LicenseManagement';
import SecondIndividualLicense from '../../pages/licences/introductionPages/SecondIndividualLicense';

interface LicenseIntroductionDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LicenseIntroductionDialog = ({open, setOpen}: LicenseIntroductionDialogProps) => {
  const [page, setPage] = useState<
    'licenseManagement' | 'firstIndividualLicense' | 'secondIndividualLicense' | 'companyLicense'
  >('licenseManagement');
  const dialogContainer = useRef(null);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 0,
          paddingBottom: 0,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <Container
        sx={{
          marginTop: appBarHeight,
          margin: 0,
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
        }}
      >
        <Box
          ref={dialogContainer}
          sx={{
            borderRadius: 4,
            height: '450px',
            padding: 4,
            width: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          {page === 'licenseManagement' && <LicenseManagement page={page} setPage={setPage} />}
          {page === 'firstIndividualLicense' && (
            <FirstIndividualLicense page={page} setPage={setPage} />
          )}
          {page === 'secondIndividualLicense' && (
            <SecondIndividualLicense page={page} setPage={setPage} />
          )}
          {page === 'companyLicense' && (
            <CompanyLicense page={page} setPage={setPage} setOpen={setOpen} />
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export default LicenseIntroductionDialog;
