import {createTheme} from '@mui/material';
import {daDK} from '@mui/material/locale';

declare module '@mui/material' {
  interface BreakpointOverrides {
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const theme = createTheme(
  {
    palette: {
      // type: "light",
      primary: {
        main: 'rgb(0,120,109)',
      },
      secondary: {
        main: '#FFA137',
      },
      error: {
        main: '#D51B21',
      },
    },
    typography: {
      fontFamily: 'Open Sans',
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1280,
        lg: 1670,
        xl: 1815,
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1200,
      },
    },
  },
  daDK
);

export default theme;
