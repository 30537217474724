import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';

import {apiClient} from '~/api/apiClient';
import {authStore} from '~/state/store';
import {OrganizationCreateType, OrganizationType} from '~/types/zod_objects';

export type OrgDetails = {
  address: string;
  created_at: string;
  cvr: string;
  id: string | null;
  is_verified: boolean;
  name: string;
  updated_at: string;
  website: string;
  zip: string;
  city: string;
  country: string;
  verified_domain?: string | null;
};

export const postOrganization = {
  mutationKey: ['postOrganization'],
  mutationFn: async (organization: OrganizationCreateType) => {
    const {data} = await apiClient.post('/admin/organizations', organization);
    return data;
  },
};

export const putOrganization = {
  mutationKey: ['putOrganization'],
  mutationFn: async (organization: OrganizationType) => {
    const {data} = await apiClient.put(`/admin/organizations`, organization);
    return data;
  },
};

export const createDomain = {
  mutationKey: ['createDomain'],
  mutationFn: async (domain: {domain: string; org_id: string}) => {
    const {data} = await apiClient.post('/admin/create_domain', domain);
    return data;
  },
};

const useCreateDomain = () => {
  const mutation = useMutation({
    ...createDomain,
    onError: (e: AxiosError<{detail: string}>) => {
      toast.error(e?.response?.data?.detail);
    },
    onSuccess: () => {
      toast.success('Domæne oprettet!');
    },
  });
  return mutation;
};

const useMyOrg = () =>
  useQuery({
    queryKey: ['getMyOrganization'],
    queryFn: async () => {
      const {data} = await apiClient.get<OrgDetails>(`/admin/my-organization`);
      return data;
    },
    refetchOnWindowFocus: false,
  });

const useOrgs = () => {
  const superUser = authStore((state) => state.superUser);
  return useQuery({
    queryKey: ['getOrganizations'],
    queryFn: async () => {
      const {data} = await apiClient.get<OrgDetails[]>(`/admin/organizations`);
      return data;
    },
    enabled: superUser,
    refetchOnWindowFocus: false,
    placeholderData: [],
  });
};
const useOrg = (id: string | null) =>
  useQuery({
    queryKey: ['getOrganization', id],
    queryFn: async () => {
      const {data} = await apiClient.get<OrgDetails>(`/admin/organization/${id}`);
      return data;
    },
    enabled: id ? true : false,
    refetchOnWindowFocus: false,
  });

const useEditOrganization = () => {
  const mutation = useMutation({
    ...putOrganization,
    onError: () => {
      toast.error('Der er opstået en fejl');
    },
    onSuccess: () => {
      toast.success('Ændring af organisation er succesfuld!');
    },
  });
  return mutation;
};

const useCreateOrganization = () => {
  const mutation = useMutation({
    ...postOrganization,
    onError: (error: Error) => {
      if ((error as any).response.statusText === 'Not Found')
        toast.error('Virksomheden findes ikke på virk');
      else if ((error as any).response.status === 406)
        toast.error('Organisationen er allerede oprettet i Calypso');
      else if ((error as any).response.status === 400) toast.error('Der er opstået en fejl');
    },
    onSuccess: () => {
      toast.success('Organisationen er oprettet!');
    },
  });
  return mutation;
};

export {useCreateDomain, useMyOrg, useOrgs, useOrg, useCreateOrganization, useEditOrganization};
