import {Fade, Box, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import Button from 'src/components/Button';
import LinearLoading from '~/components/LineLoader';

interface DomainAcceptedProps {
  page: 'domainOrgFound' | 'domainAccepted';
  setPage: React.Dispatch<React.SetStateAction<'domainOrgFound' | 'domainAccepted'>>;
  isMutating: boolean;
  userCreated: boolean;
  mutationError: string | null;
}

const DomainAccepted = ({
  page,
  isMutating,
  userCreated,
  mutationError,
  setPage,
}: DomainAcceptedProps) => {
  const navigate = useNavigate();

  return (
    <Fade in={page == 'domainAccepted'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        {!isMutating && userCreated && (
          <>
            <Box>
              <Typography variant="h4">Information</Typography>
            </Box>

            <Box>
              <Typography>
                Du får tilsendt en email med instruktioner til at fuldføre oprettelsen af din
                bruger. Tjek eventuelt spam, hvis du ikke kan finde mailen.
              </Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'end', mt: 4}}>
              <Button
                onClick={() => {
                  navigate('/');
                }}
                bttype="primary"
                sx={{width: '200px', margin: 1, marginX: 'auto'}}
              >
                Afslut
              </Button>
            </Box>
          </>
        )}
        {!isMutating && !userCreated && (
          <>
            <Box>
              <Typography variant="h4">Der skete en fejl under oprettelsen</Typography>
            </Box>

            <Box>
              <>
                <Typography>{mutationError}</Typography>
              </>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <Button
                bttype="primary"
                onClick={() => {
                  setPage('domainOrgFound');
                }}
              >
                Gå tilbage
              </Button>
            </Box>
          </>
        )}
        {isMutating && (
          <Box
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}
          >
            <LinearLoading width="large" height="large" color="primary" />
          </Box>
        )}
      </Box>
    </Fade>
  );
};

export default DomainAccepted;
