import { Checkbox, FormControlLabel } from '@mui/material';
import type { CheckboxProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactNode } from 'react';
import { Control, FieldValues, Path, RegisterOptions } from 'react-hook-form';

type FormInputCheckboxProps<TFieldValues extends FieldValues = FieldValues> = {
  name: Path<TFieldValues>;
  label?: string;
  rules?: RegisterOptions<TFieldValues>;
  value?: string;
} & Omit<CheckboxProps, 'name' | 'onBlur' | 'ref' | 'onChange' | 'checked'>;

const FormInputCheckbox = ({ name, label, rules, value, ...otherProps }: FormInputCheckboxProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange, onBlur, ref, name } }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...otherProps}
              name={name}
              checked={value || false}
              onChange={(e) => onChange(e.target.checked)}
              onBlur={onBlur}
              inputRef={ref}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default FormInputCheckbox;
