import {Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

import Button from '~/components/Button';

interface MissingInvoiceDetailsDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setChangeLicensingDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MissingInvoiceDetailsDialog = ({
  isOpen,
  setIsOpen,
  setChangeLicensingDialogOpen,
}: MissingInvoiceDetailsDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
          paddingBottom: 0,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5">Faktureringsoplysninger mangler</Typography>
      </DialogTitle>
      <DialogContent>
        Der mangles faktureringsoplysninger for denne organisation. Indtast venligst
        faktureringsoplysninger for at fortsætte med oprettelsen af en Premium licens.
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)} bttype="tertiary">
          Annuller
        </Button>

        <Button onClick={() => setChangeLicensingDialogOpen(true)} bttype="primary">
          Indtast faktureringsoplysninger
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MissingInvoiceDetailsDialog;
