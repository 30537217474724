import {Fade, Box, Typography} from '@mui/material';
import React from 'react';
import {useFormContext} from 'react-hook-form';

import Button from 'src/components/Button';
import FormInput from '~/components/FormInput';
import type {CvrData} from '~/api/functions';
import {verifyCvr} from '~/api/functions';
import type {RegisterFormValues} from '~/hooks/queries/useLogin';

interface EnterCVRProps {
  page: string;
  setPage: React.Dispatch<
    React.SetStateAction<'decideOrgOrNoOrg' | 'enterCVR' | 'confirmOrg' | 'creationConfirmed'>
  >;
  setCvrData: React.Dispatch<React.SetStateAction<CvrData | null>>;
}

const EnterCVR = ({page, setPage, setCvrData}: EnterCVRProps) => {
  const {getValues} = useFormContext<RegisterFormValues>();

  const {setError, clearErrors, trigger} = useFormContext<RegisterFormValues>();
  const findOrgInfo = async (cvr: string) => {
    const isValid = await trigger('cvr');

    if (!isValid) {
      return;
    }

    setPage('confirmOrg');
    verifyCvr(cvr).then((res) => {
      if (res == null) {
        setError('cvr', {type: 'custom', message: 'Denne virksomhed findes ikke i Virk.'});
        setCvrData(null);
        setPage('enterCVR');
        return;
      }
      if ('error' in res && res.error == 'exists') {
        setError('cvr', {
          type: 'custom',
          message:
            'Denne virksomhed findes allerede i Calypso. Spørg din administrator om at invitere dig.',
        });
        setCvrData(null);
        setPage('enterCVR');
        return;
      } else {
        if (!('error' in res)) {
          setCvrData(res);
        }

        clearErrors('cvr');
        return;
      }
    });
  };

  return (
    <Fade in={page == 'enterCVR'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box mb={4}>
          <Typography variant="h5">Indtast CVR</Typography>
        </Box>

        <Box>
          <FormInput label="CVR nummer" name="cvr" type="string" required />

          <div>
            <Typography mt={4}>
              Indtast CVR nummeret for den virksomhed du gerne vil oprette. Vi finder automatisk
              info om virksomheden.
            </Typography>
          </div>
        </Box>

        <Box sx={{display: 'flex', justifyContent: 'end', gap: 2, mt: 4}}>
          <Button onClick={() => setPage('decideOrgOrNoOrg')} bttype="tertiary">
            Gå tilbage
          </Button>
          <Button
            bttype="primary"
            onClick={() => {
              const cvr = getValues('cvr');
              if (cvr) {
                findOrgInfo(cvr);
              }
            }}
          >
            Find organisation
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};

export default EnterCVR;
