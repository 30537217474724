import {z} from 'zod';

import {Role} from '~/state/store';

import {
  address,
  city,
  country,
  cvr,
  email,
  first_name,
  name,
  last_name,
  mobile,
  newsletter,
  organization_id,
  role,
  website,
  zip,
} from './consts';

export const EditLicensingSchema = z.object({
  name: name,
  address: z.string().min(1, 'Adresse er påkrævet'),
  cvr: cvr,
  zip: zip,
  city: city,
  country: country,
  website: website,
  email: email,
  att: z.string({required_error: 'Ansvarsperson er påkrævet'}),
  ean: z.string().length(13, 'EAN skal være præcis 13 tegn langt').optional().nullish(),
  entity_id: z.string(),
});

export const AddUserSchema = z.object({
  first_name,
  last_name,
  email,
  org_role: role,
  organization_id,
});

export const EditUserSchema = z.object({
  first_name,
  last_name,
  mobile,
  license: z.string().optional().nullish(),
  // email: z.string({ required_error: 'Email er på krævet' }).email('Ugyldig email'),
  organization_id,
});

export const InviteUserSchema = z.object({
  email,
  role,
  org_id: organization_id,
});

export const EmailPreferencesSchema = z.object({
  email: email,
  software: z.boolean(),
  vandloeb_overfladevand: z.boolean(),
  grundvand: z.boolean(),
  natur: z.boolean(),
  produkter_vandloeb: z.boolean(),
  produkter_grundvand: z.boolean(),
  dambrug: z.boolean(),
});

// schema model
export const AddOrganizationSchema = z.object({
  name,
  address,
  zip,
  city,
  country,
  website,
  cvr,
});

export const ProjectCreationSchema = z.object({
  organization_id: z
    .string({required_error: 'Organisation er påkrævet'})
    .min(2, 'Organisation er påkrævet'),
  customer_name: z
    .string({required_error: 'Kunde navn er påkrævet'})
    .min(2, 'Kunde navn er påkrævet'),
  is_customer_service: z.boolean(),
  project_no: z
    .string({required_error: 'Projekt nummer er påkrævet'})
    .min(2, 'Projekt nummer er påkrævet'),
  project_info: z.string({required_error: 'Projekt info er påkrævet'}),
  project_title: z
    .string({required_error: 'Projekt titel er påkrævet'})
    .min(2, 'Projekt titel er påkrævet'),
});

export type ProjectCreationType = z.infer<typeof ProjectCreationSchema>;

export type OrganizationType = z.infer<typeof AddOrganizationSchema> & {id: string};
export type OrganizationCreateType = z.infer<typeof AddOrganizationSchema>;
export type OrganizationEditType = z.infer<typeof AddOrganizationSchema>;

export type UserCreateType = z.infer<typeof AddUserSchema>;
export type UserEditType = z.infer<typeof EditUserSchema>;
export type InviteUserType = z.infer<typeof InviteUserSchema>;
export type EmailPreferencesType = z.infer<typeof EmailPreferencesSchema>;
export type EditLicensingType = z.infer<typeof EditLicensingSchema>;

export enum License {
  GRATIS = 'GRATIS',
  PREMIUM = 'PREMIUM',
}

export type LicenseTableType = {
  type: string;
  user_id?: string | null;
  license_id: string;
  start_date: string;
  renew: boolean;
};

export type InvoiceDetails = {
  address: string;
  att: string;
  city: string;
  country: string;
  created_at: string;
  cvr: string;
  ean: string;
  email: string;
  entity_id: string;
  id: string;
  name: string;
  updated_at: string;
  zip: string;
};

export type LicensingPair = {
  user_id: string | null;
  license_id: string | null;
};

export type SwitchLicensingType = {
  licenseChanges: LicensingPair[];
  org_id: string;
};

export type PostLicensingType = {
  org_id: string;
  count: number;
  user_id?: string | null;
};

export type LicenseInfoType = {
  premium_licences: number;
  paid_for_licences: number;
  available_licenses: number;
  used_licenses: number;
};

export type Project = {
  customer_name: string;
  is_customer_service: boolean;
  project_title: string;
  project_no: string;
};

export type Unit = {
  calypso_id: string | null;
  channel: number | null;
  comment: string | null;
  enddate: string | null;
  gid: string | null;
  projectno: string | null;
  scale_factor: number | null;
  sensor_id: string | null;
  annual_price: number | null;
  signal_id: number | null;
  project_title: string | null;
  startdate: string | null;
  terminal_id: string | null;
  timezone: string | null;
  ts_id: string | null;
  ts_type: string | null;
  ts_unit: string | null;
  ts_data: string[] | null;
  ts_logtype: string[] | null;
  unit_uuid: string | null;
  updated_at: string | null;
  loc_id: string | null;
  loc_name: string | null;
  mainloc: string | null;
  subloc: string | null;
  loc_description: string | null;
  subRows?: Unit[];
};

export type Permissions = {
  org_roles: {
    org_id: string;
    role: string;
  }[];
};

export type PermissionsMutation = {
  user_id: string;
  permissions: {
    org_id: string;
    role: string;
  };
};

export type UserType = {
  first_name: string;
  last_name: string;
  email: string;
  org_role: Role;
  organization_id: string;
  organization_name: string;
  created_at: string;
  updated_at: string;
  id: string;
  password?: string;
  license: string | undefined;
  mobile?: string | undefined;
  is_verified?: boolean;
  newsletter?: boolean;
};

export type UserPost = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  org_role: Role;
  organization_id: string;
};
