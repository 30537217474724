import './index.css';
import {ThemeProvider, StyledEngineProvider} from '@mui/material';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {PersistQueryClientProvider} from '@tanstack/react-query-persist-client';
import React from 'react';
import {Container, createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {registerSW} from 'virtual:pwa-register';

import {queryClient, persister} from './api/queryClient';
import App from './App';
// import NetworkStatus from './components/NetworkStatus';
import theme from './theme';

/* if ('serviceWorker' in navigator) {
  // && !/localhost/.test(window.location)) {
  registerSW({
    onNeedRefresh() {
      if (confirm('Opdatering tilgængelig. Vil du opdatere?')) {
        // updateSW(true);
      }
    },
  });
} */

const container = document.getElementById('root');
const root = createRoot(container as Container);

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{persister}}
            onSuccess={() => {
              queryClient.resumePausedMutations().then(() => {
                queryClient.resetQueries();
              });
            }}
          >
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
            <ToastContainer />
            {/* <NetworkStatus /> */}
          </PersistQueryClientProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  </BrowserRouter>
);
