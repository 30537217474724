import {Link} from '@mui/material';
import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import {apiClient} from '~/api/apiClient';
import {authStore} from '~/state/store';
import {
  EditLicensingType,
  InvoiceDetails,
  License,
  LicenseInfoType,
  LicenseTableType,
  PostLicensingType,
  SwitchLicensingType,
} from '~/types/zod_objects';

import {useOrgRole} from './permissions';

const putEditLicensing = {
  mutationKey: ['putEditLicensing'],
  mutationFn: async (licensing: EditLicensingType) => {
    const {data} = await apiClient.put('/admin/license/organizations/invoice_details', licensing);
    return data;
  },
};

const useInvoiceDetails = (orgId: string) => {
  const superUser = authStore((state) => state.superUser);
  const role = useOrgRole(orgId ?? '');
  const editPermissions = role === 'ADMIN' || superUser;
  return useQuery({
    queryKey: ['getInvoiceDetails', orgId],
    queryFn: async () => {
      const {data} = await apiClient.get<InvoiceDetails>(
        `/admin/license/organizations/invoice_details/${orgId}`
      );
      return data;
    },
    enabled: editPermissions && orgId !== '',
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60,
  });
};

const useEditLicensing = () => {
  const mutation = useMutation({
    ...putEditLicensing,
    onError: () => {
      toast.error('Der er opstået en fejl');
    },
    onSuccess: () => {
      toast.success('Ændring af faktureringsoplysninger er succesfuld');
    },
  });
  return mutation;
};

const usePostLicensing = () => {
  const mutation = useMutation({
    mutationKey: ['postLicensing'],
    mutationFn: async (licensing: PostLicensingType) => {
      const {data} = await apiClient.post('/admin/license/organizations/licensing', licensing);
      return data;
    },
    onError: () => {
      toast.error('Der er opstået en fejl');
    },
    onSuccess: () => {
      toast.success('Oprettelse af licens er succesfuld!');
    },
  });
  return mutation;
};

const editInvoiceMsg = (editPath: string) => (
  <div>
    Der er ingen faktureringsoplysninger for denne organisation.{' '}
    <Link href={editPath}>Opret faktureringsoplysninger</Link>
  </div>
);
const useSwitchLicensingMutation = () => {
  const params = useParams();
  const org_id = params.orgId;
  const editPath = `${org_id}/edit`;
  return useMutation({
    mutationFn: async (payload: SwitchLicensingType) => {
      const {data} = await apiClient.put(`/admin/license/organizations/licensing/user`, payload);
      return data;
    },
    onSuccess: () => {
      toast.success('Licensændring er succesfuld');
    },
    onError: (e: AxiosError<{detail: string}>) => {
      const msg = e?.response?.data?.detail;
      if (msg === 'Ingen faktureringsoplysninger fundet') {
        toast.error(editInvoiceMsg(editPath));
      } else {
        toast.error(msg);
      }
    },
  });
};

const useLicenseInfo = (org_id: string) => {
  const superUser = authStore((state) => state.superUser);
  const role = useOrgRole(org_id ?? '');
  const editPermissions = role === 'ADMIN' || superUser;
  return useQuery({
    queryKey: ['getLicenseInfo', org_id],
    queryFn: async () => {
      const {data} = await apiClient.get<LicenseInfoType>(
        `/admin/license/organizations/licensing/${org_id}`
      );
      return data;
    },
    enabled: editPermissions,
    refetchOnWindowFocus: false,
  });
};

const useLicensingTable = (org_id: string) =>
  useQuery({
    queryKey: ['getLicensingTable', org_id],
    queryFn: async () => {
      const {data} = await apiClient.get<LicenseTableType[]>(
        `/admin/license/organizations/licensing/table/${org_id}`
      );
      return data;
    },
    refetchOnWindowFocus: false,
  });

const mapLicenseEnumToString = (license: string | undefined) => {
  switch (license) {
    case License.GRATIS:
      return 'Gratis';
    case License.PREMIUM:
      return 'Premium';
    case undefined:
      return 'Gratis';
  }
};

export {
  useInvoiceDetails,
  useEditLicensing,
  useSwitchLicensingMutation,
  useLicenseInfo,
  useLicensingTable,
  mapLicenseEnumToString,
  usePostLicensing,
};
