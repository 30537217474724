import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import {Fade, Box, Typography} from '@mui/material';
import React from 'react';

import TilføjLicens from '~/assets/TilføjLicens.png';
import VælgBruger from '~/assets/VælgBruger.png';
import Button from '~/components/Button';

interface FirstIndividualLicenseProps {
  page:
    | 'licenseManagement'
    | 'firstIndividualLicense'
    | 'secondIndividualLicense'
    | 'companyLicense';
  setPage: React.Dispatch<
    React.SetStateAction<
      'licenseManagement' | 'firstIndividualLicense' | 'secondIndividualLicense' | 'companyLicense'
    >
  >;
}

const FirstIndividualLicense = ({setPage, page}: FirstIndividualLicenseProps) => {
  return (
    <Fade in={page === 'firstIndividualLicense'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box>
          <Typography mb={2} textAlign={'center'} variant="h5">
            <b>Individuel Licensstyring</b>
          </Typography>
        </Box>

        <Box>
          <div>
            <Typography mb={1}>
              Ved individuel licenshåndtering betaler du for hvert enkel aktiv licens. Én premium
              licens koster 2400 kr. årligt.
            </Typography>
            <Typography mb={2}> Gå til Licenstabellen for at administrere dem:</Typography>
            <Box
              mb={2}
              mt={3}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                marginRight: 10,
              }}
            >
              <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                <Typography>Opret ny Licens &nbsp;</Typography>
                <ArrowForward />
              </Box>
              <img style={{width: '150px'}} src={TilføjLicens} alt="Tilføj Licens" />
            </Box>
            <Box
              mb={0}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'start', pb: 2}}>
                <Typography>Vælg bruger &nbsp; </Typography>
                <ArrowForward />
              </Box>
              <img style={{width: '300px'}} src={VælgBruger} alt="Tilføj Licens" />
            </Box>
          </div>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'center', mt: 0}}>
          <Box sx={{display: 'flex', gap: 2}}>
            <Button
              startIcon={<ArrowBack />}
              onClick={() => setPage('licenseManagement')}
              bttype="primary"
            >
              Tilbage
            </Button>
            <Button
              endIcon={<ArrowForward />}
              onClick={() => setPage('secondIndividualLicense')}
              bttype="primary"
            >
              Fortsæt
            </Button>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};
export default FirstIndividualLicense;
