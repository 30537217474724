import Autocomplete from '@mui/material/Autocomplete';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import {ReactNode} from 'react';
import {Controller, FieldValues, get, Path, RegisterOptions, useFormContext} from 'react-hook-form';

import {OrgDetails} from '~/hooks/queries/organizations';

type OrganizationDropdownProps<TFieldValues extends FieldValues = FieldValues> = {
  control: any;
  organizations: OrgDetails[] | undefined;
  name: Path<TFieldValues>;
  warning?: (value: any) => boolean;
  children?: ReactNode;
  rules?: RegisterOptions<TFieldValues>;
} & Omit<TextFieldProps, 'name' | 'ref' | 'onChange' | 'error' | 'helperText'>;

const OrganizationDropdown: React.FC<OrganizationDropdownProps> = ({
  name,
  organizations,
  warning,
  rules,
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({field: {name, onChange, value, onBlur, ref}}) => {
        return (
          <Autocomplete
            id="organization_id"
            ref={ref}
            value={organizations?.find((org) => org.id === value) || null}
            onChange={(_event, value) => {
              onChange(value?.id || '');
            }}
            onBlur={onBlur}
            options={organizations || []}
            getOptionLabel={(option) => option.name + ' - ' + option.address}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                label="Organisation"
                required
                error={!!get(errors, name)}
                helperText={
                  get(errors, name)
                    ? get(errors, name).message
                    : warning && warning(value)
                      ? 'Warning'
                      : ''
                }
                fullWidth
                sx={{mb: 2}}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <span>
                  {option.name} - {option.address}
                </span>
              </li>
            )}
          />
        );
      }}
    />
  );
};

export default OrganizationDropdown;
