// A hook that returns whether the current screen size is mobile, tablet or desktop.
import {merge} from 'lodash';
import {
  type MRT_RowData,
  type MRT_TableOptions,
  type MRT_ColumnDef,
  useMaterialReactTable,
  type MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_DA} from 'material-react-table/locales/da';

import RenderInternalActions from '~/components/RenderInternalActions';
import useBreakpoints from '~/hooks/useBreakpoints';

export enum TableTypes {
  STATIONTABLE = 'stationTable',
  TABLE = 'table',
  LIST = 'list',
  SCROLLING = 'scrolling',
}

const getOptions = <TData extends MRT_RowData>(
  breakpoints: ReturnType<typeof useBreakpoints>,
  type: string
) => {
  const globalOptions: Partial<MRT_TableOptions<TData>> = {
    localization: MRT_Localization_DA,
    enablePagination: true,
    globalFilterFn: 'fuzzy',
    enableStickyFooter: true,
    // renderToolbarInternalActions: ({table}) => {
    //   return RenderInternalActions({table});
    // },
    positionExpandColumn: 'last',
    muiPaginationProps: {
      size: 'small',
      showRowsPerPage: false,
      color: 'primary',
      shape: 'rounded',
      variant: 'outlined',
    },
    paginationDisplayMode: 'pages',
    muiTablePaperProps: {
      sx: {
        width: '100%',
        flex: '1 1 0',
        display: 'flex',
        flexFlow: 'column',
        boxShadow: breakpoints.isMobile ? 'none' : '1',
      },
    },

    muiSearchTextFieldProps: {
      variant: 'outlined',
      InputProps: {sx: {borderRadius: '9999px'}},
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        header: '',
        size: 10,
        muiTableHeadCellProps: {
          sx: {
            padding: 0,
          },
        },
      },
    },
    muiTableContainerProps: {
      sx: {
        width: '100%',
        flex: '1 1 0',
        height: 'inherit',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        boxShadow: 'none',
      },
    },
  };

  const mobileScrollingOptions: Partial<MRT_TableOptions<TData>> = merge({}, globalOptions, {
    localization: MRT_Localization_DA,
    globalFilterFn: 'fuzzy',
    enablePagination: false,
    renderToolbarInternalActions: ({table}) => {
      return RenderInternalActions({table});
    },
    positionExpandColumn: 'last',
    muiTablePaperProps: {
      sx: {
        width: '100%',
        flex: '1 1 0',
        display: 'flex',
        flexFlow: 'column',
        boxShadow: breakpoints.isMobile ? 'none' : '1',
        height: '100%',
      },
    },
    muiSearchTextFieldProps: {
      variant: 'outlined',
      InputProps: {sx: {borderRadius: '9999px', width: '100%'}},
      sx: {
        width: '100%', // Make the search bar take up the entire width
        '.MuiCollapse-wrapperInner': {
          width: '100%',
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        header: '',
        size: 10,
        muiTableHeadCellProps: {
          sx: {
            padding: 0,
          },
        },
      },
    },
    muiTableContainerProps: {
      sx: {
        width: '100%',
        flex: '1 1 0',
        height: 'inherit',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        padding: 0.5,
        paddingLeft: 1,
      },
    },
    muiTableBodyCellProps: {
      sx: {
        padding: 0.5,
        paddingLeft: 1,
      },
    },
    enableStickyHeader: true,
    enableGlobalFilterRankedResults: true,
    enableColumnActions: breakpoints.isTouch ? false : true,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
    },
    enableTableFooter: false,
  } as Partial<MRT_TableOptions<TData>>);

  const mobileOptions: Partial<MRT_TableOptions<TData>> = merge({}, globalOptions, {
    localization: MRT_Localization_DA,
    globalFilterFn: 'fuzzy',
    enablePagination: true,
    renderToolbarInternalActions: ({table}) => {
      return RenderInternalActions({table});
    },
    positionExpandColumn: 'last',
    muiTablePaperProps: {
      sx: {
        width: '100%',
        flex: '1 1 0',
        display: 'flex',
        flexFlow: 'column',
        boxShadow: breakpoints.isMobile ? 'none' : '1',
        height: '2000px',
      },
    },
    muiSearchTextFieldProps: {
      variant: 'outlined',
      InputProps: {sx: {borderRadius: '9999px', width: '100%'}},
      sx: {
        width: '100%', // Make the search bar take up the entire width
        '.MuiCollapse-wrapperInner': {
          width: '100%',
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        header: '',
        size: 10,
        muiTableHeadCellProps: {
          sx: {
            padding: 0,
          },
        },
      },
    },
    muiTableContainerProps: {
      sx: {
        width: '100%',
        flex: '1 1 0',
        height: '2000px',
        maxHeight: 'none',
      },
    },
    muiTableHeadProps: {
      sx: {},
    },
    muiTableHeadRowProps: {
      sx: {},
    },
    muiTableHeadCellProps: {
      sx: {
        padding: 0.5,
        paddingLeft: 1,
        backgroundColor: 'grey.200',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        padding: 0.5,
        paddingLeft: 1,
        height: '38px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    enableStickyHeader: true,
    enableGlobalFilterRankedResults: true,
    enableColumnResizing: true,

    initialState: {
      showGlobalFilter: true,
      density: 'compact',
    },
    enableTableFooter: false,
  } as Partial<MRT_TableOptions<TData>>);

  const mobileListOptions: Partial<MRT_TableOptions<TData>> = merge({}, globalOptions, {
    enableTableFooter: false,
    enableTableHead: false,
    enableTopToolbar: false,
    enableExpandAll: false,
    enableExpanding: false,
    enableColumnActions: false,
    muiTableBodyCellProps: {
      sx: {
        borderRadius: 9999,
        border: 'none',
        backgroundColor: 'grey.300',
        alignContent: 'space-between',
      },
    },
    muiTableBodyRowProps: {
      // The following styling is neccessary to make sure the detail panel look as a part of the row. The purpose of the styling is to shift the detail panel upwards so that it aligns with the row.
      sx: {
        display: 'table-row !important',
        border: 'none',
        backgroundColor: 'grey.300',
        background: 'grey.300',
        mt: -7.7,
        px: 2,
        mx: -2,
        transition: 'transform 0.2s',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      },
    },
    muiTableProps: {
      sx: {
        borderSpacing: 5,
        border: 'none',
      },
    },

    initialState: {
      density: 'compact',
      columnVisibility: {
        'mrt-row-expand': false,
      },
    },
    /* muiDetailPanelProps: {
      sx: {
        border: 'none',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      },
    }, */
    // muiExpandButtonProps: ({row}) => ({
    //   sx: {
    //     transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
    //     transition: 'transform 0.2s',
    //   },
    // }),
  } as Partial<MRT_TableOptions<TData>>);

  const desktopListOptions: Partial<MRT_TableOptions<TData>> = merge({}, globalOptions, {
    ...globalOptions,
    enableTableFooter: true,
    enableStickyHeader: true,
    // enableGlobalFilterRankedResults: true,
    enablePagination: false,
    positionGlobalFilter: 'right',
    enableColumnActions: breakpoints.isTouch ? false : true,
    enableTableHead: true,
    enableFilter: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 100, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
        grow: false,
      },
    },
    initialState: {
      showGlobalFilter: true,
      density: 'comfortable',
    },
    positionActionsColumn: 'last',
  } as Partial<MRT_TableOptions<TData>>);

  const desktopOptions: Partial<MRT_TableOptions<TData>> = merge({}, globalOptions, {
    ...globalOptions,
    enableTableFooter: true,
    enableStickyHeader: true,
    enableGlobalFilterRankedResults: true,
    positionGlobalFilter: 'right',
    enableColumnActions: breakpoints.isTouch ? false : true,
    enableTableHead: true,
    enableFilter: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 100, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
        grow: false,
      },
    },
    initialState: {
      showGlobalFilter: true,
      density: 'comfortable',
    },
    positionActionsColumn: 'last',
  } as Partial<MRT_TableOptions<TData>>);

  if (breakpoints.isMobile) {
    if (type === TableTypes.SCROLLING) return mobileScrollingOptions;

    return mobileOptions;
  }

  if (breakpoints.isMobile && type === TableTypes.LIST) {
    return mobileListOptions;
  }
  // if (breakpoints.isMobile && type === TableTypes.TABLE) {
  //   return globalOptions;
  // }

  if (type === TableTypes.SCROLLING) return desktopListOptions;

  return desktopOptions;
};

export const useTable = <TData extends MRT_RowData>(
  columns: MRT_ColumnDef<TData>[],
  data: TData[] | undefined,
  options?: Partial<MRT_TableOptions<TData>>,
  tableState?: Partial<MRT_TableOptions<TData>> | undefined,
  type: string = TableTypes.STATIONTABLE
): MRT_TableInstance<TData> => {
  const breakpoints = useBreakpoints();
  const tableOptions = merge({}, getOptions<TData>(breakpoints, type), options, {
    initialState: {
      isLoading: data === undefined,
      ...tableState?.initialState,
    },
  });

  const table = useMaterialReactTable({
    columns,
    data: data ?? [],
    ...tableState,
    state: {
      ...tableState?.state,
      isLoading: data === undefined,
    },
    ...tableOptions,
  });

  return table;
};
