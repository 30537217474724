import {Box} from '@mui/material';
import React from 'react';

import DomainAccepted from './DomainAccepted';
import DomainOrgFound from './DomainOrgFound';

interface ConfirmDomainDialogProps {
  domainOrg: string;
  backToRegister: () => void;
  createUser: () => void;
  userCreated: boolean;
  isMutating: boolean;
  mutationError: string | null;
}

const ConfirmDomainDialog = ({
  domainOrg,
  backToRegister,
  createUser,
  isMutating,
  userCreated,
  mutationError,
}: ConfirmDomainDialogProps) => {
  const [page, setPage] = React.useState<'domainOrgFound' | 'domainAccepted'>('domainOrgFound');
  const dialogContainer = React.useRef<HTMLDivElement | null>(null);

  return (
    <Box
      ref={dialogContainer}
      sx={{
        width: '500px',
        height: '350px',
        borderRadius: 4,
        padding: '48px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      }}
    >
      {page == 'domainOrgFound' && (
        <DomainOrgFound
          page={page}
          setPage={setPage}
          domainOrg={domainOrg}
          backToRegister={backToRegister}
          createUser={createUser}
        />
      )}
      {page == 'domainAccepted' && (
        <DomainAccepted
          page={page}
          setPage={setPage}
          isMutating={isMutating}
          userCreated={userCreated}
          mutationError={mutationError}
        />
      )}
    </Box>
  );
};

export default ConfirmDomainDialog;
