import {ContentCopy, Edit} from '@mui/icons-material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonIcon from '@mui/icons-material/Person';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import {AxiosError} from 'axios';
import React, {useState} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';

import Button from '~/components/Button';
import EditLicensingDialog from '~/components/dialogs/EditLicensingDialog';
import MissingInvoiceDetailsDialog from '~/components/dialogs/MissingInvoiceDetailsDialog';
import LinearLoading from '~/components/LineLoader';
import {useInvoiceDetails} from '~/hooks/queries/licenses';
import {useOrg} from '~/hooks/queries/organizations';
import {useOrgRole} from '~/hooks/queries/permissions';
import {useUser, mapRoleEnumToString} from '~/hooks/queries/users';
import {useCopyToClipboard} from '~/hooks/useCopyToClipboard';
import {authStore} from '~/state/store';

import EditUserDialog from '../../components/dialogs/EditUserDialog';
import SelfChangePasswordDialog from '../../components/dialogs/SelfChangePasswordDialog';

interface UserDetailsProps {
  isProfile?: boolean;
}

const UserDetails = ({isProfile = false}: UserDetailsProps) => {
  const {user_id, superUser, adminAccess} = authStore();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isEditLicensingOpen, setIsEditLicensingOpen] = useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [isMissingInvoiceDetailsDialogOpen, setIsMissingInvoiceDetailsDialogOpen] = useState(false);

  const [copiedText, copy] = useCopyToClipboard();
  const location = useLocation();
  const userId = isProfile
    ? user_id
    : location.pathname.split('/')[2]
      ? location.pathname.split('/')[2]
      : user_id;
  const {data: user, isPending: isPendingUser, error} = useUser(userId ?? '');
  const {data: organisation} = useOrg(user?.organization_id ?? '');
  const {data: invoiceDetails} = useInvoiceDetails(user?.organization_id ?? '');
  console.log('invoiceDetails', invoiceDetails);
  const rolePermissions = useOrgRole(user?.organization_id);
  const canChangePassword = user_id === userId;
  let editRights;
  if (rolePermissions === 'SUPERUSER') {
    editRights = true;
  } else if (user_id == userId) {
    editRights = true;
  } else {
    editRights = false;
  }

  if (isPendingUser) {
    return (
      <Box sx={{height: '88vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <LinearLoading width="large" height="large" color="primary" />
      </Box>
    );
  }

  if (error) {
    const axiosError = error as AxiosError;
    if (axiosError?.response?.status === 404) {
      return <Typography variant="h5">Brugeren blev ikke fundet</Typography>;
    }
    return <Typography variant="h5">Du har ikke adgang til at se denne bruger</Typography>;
  }

  const role = mapRoleEnumToString(user.org_role);

  return (
    <Box margin={0} marginTop={0}>
      <EditUserDialog
        open={isDetailsOpen}
        setOpen={setIsDetailsOpen}
        user={user}
        setMissingInvoiceDetailsOpen={setIsMissingInvoiceDetailsDialogOpen}
      />
      <SelfChangePasswordDialog open={isPasswordDialogOpen} setOpen={setIsPasswordDialogOpen} />
      <MissingInvoiceDetailsDialog
        isOpen={isMissingInvoiceDetailsDialogOpen}
        setIsOpen={setIsMissingInvoiceDetailsDialogOpen}
        setChangeLicensingDialogOpen={setIsEditLicensingOpen}
      />
      {(superUser || adminAccess) && (
        <EditLicensingDialog
          open={isEditLicensingOpen}
          setOpen={setIsEditLicensingOpen}
          organization={organisation}
          invoiceDetails={invoiceDetails}
          setMissingInvoiceDetailsOpen={setIsMissingInvoiceDetailsDialogOpen}
          setEditUserDialogOpen={setIsDetailsOpen}
          users_org_id={user?.organization_id}
        />
      )}
      <Card
        elevation={4}
        sx={{width: '100%', height: '100%', my: 1, mt: 0, display: 'flex', flexWrap: 'wrap'}}
      >
        <CardContent sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Box width={'100%'}>
            {!isPendingUser ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    justifyContent: 'center',
                    mb: 2,
                  }}
                >
                  <Avatar sx={{width: 100, height: 100}}>
                    <PersonIcon sx={{width: 100, height: 100}} />
                  </Avatar>
                  <Typography variant="h5" sx={{mt: 3, fontWeight: 'bold'}}>
                    {user?.first_name} {user?.last_name}
                  </Typography>
                  <Divider sx={{mt: 3}} />

                  <Typography variant="body2" sx={{mt: 3, fontWeight: 'bold'}}>
                    Email:
                  </Typography>
                  <Box display="flex" alignItems="center" height={'20px'}>
                    <Typography variant="body2"> {user?.email}</Typography>
                    <Tooltip title={copiedText ? 'Kopieret!' : 'Kopier'} placement="top">
                      <IconButton
                        onClick={() => {
                          copy(user?.email);
                        }}
                      >
                        <ContentCopy fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography variant="body2" sx={{mt: 3, fontWeight: 'bold'}}>
                    Organisation:
                  </Typography>
                  {user?.organization_id ? (
                    <Link component={RouterLink} to={`/organizations/${user?.organization_id}`}>
                      <Typography variant="body2"> {user?.organization_name}</Typography>
                    </Link>
                  ) : (
                    <Typography variant="body2"> Ingen organisation</Typography>
                  )}

                  <Typography variant="body2" sx={{mt: 3, fontWeight: 'bold'}}>
                    Rettighed:
                  </Typography>
                  <Typography variant="body2">{role}</Typography>

                  <Typography variant="body2" sx={{mt: 3, fontWeight: 'bold'}}>
                    Verificeret:
                  </Typography>
                  <Typography variant="body2"> {user?.is_verified ? 'Ja' : 'Nej'}</Typography>

                  <Typography variant="body2" sx={{mt: 3, fontWeight: 'bold'}}>
                    Mobilnr:
                  </Typography>
                  <Typography variant="body2">
                    {' '}
                    {user?.mobile ? user.mobile : 'Ikke angivet'}
                  </Typography>

                  <Typography variant="body2" sx={{mt: 3, fontWeight: 'bold'}}>
                    Licens:
                  </Typography>
                  <Typography variant="body2"> {user.license ? 'Premium' : 'Gratis'}</Typography>

                  {/* {canChangePassword && (
                    <Link
                      sx={{
                        textDecoration: 'none',
                        ':hover': {cursor: 'pointer', textDecoration: 'underline'},
                        mt: 3,
                        mb: 2,
                      }}
                      onClick={() => setIsPasswordDialogOpen(true)}
                    >
                      <Typography variant="body2"> Skift adgangskode</Typography>
                    </Link>
                  )} */}

                  {editRights && (
                    <Button
                      sx={{marginTop: 2, width: '120px'}}
                      onClick={() => setIsDetailsOpen(true)}
                      bttype="primary"
                      startIcon={<Edit />}
                    >
                      Rediger
                    </Button>
                  )}
                  {canChangePassword && (
                    <Button
                      sx={{marginTop: 2, width: '180px'}}
                      onClick={() => setIsPasswordDialogOpen(true)}
                      bttype="primary"
                      startIcon={<LockOpenIcon />}
                    >
                      Skift adgangskode
                    </Button>
                  )}
                </Box>
              </>
            ) : (
              <LinearLoading width="large" height="large" color="primary" />
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserDetails;
