import {Fade, Box, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import Button from 'src/components/Button';
import LinearLoading from '~/components/LineLoader';

interface UserCreatedProps {
  page: string;
  setPage: React.Dispatch<
    React.SetStateAction<'decideOrgOrNoOrg' | 'enterCVR' | 'confirmOrg' | 'creationConfirmed'>
  >;
  isMutating: boolean;
  userCreated: boolean;
  backToRegister: () => void;
  mutationError: string | null;
}

const UserCreated = ({
  page,
  setPage,
  isMutating,
  userCreated,
  backToRegister,
  mutationError,
}: UserCreatedProps) => {
  const navigate = useNavigate();

  return (
    <Fade in={page == 'creationConfirmed'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <>
          {!isMutating && userCreated && (
            <>
              <Box>
                <Typography variant="h5">Din bruger er oprettet!</Typography>
              </Box>
              <Box>
                <>
                  <Box>
                    <Typography>
                      Følg anvisningerne i bekræftigelsesmailen, der er sendt til dig, for at
                      fuldende oprettelsen.
                    </Typography>
                  </Box>
                </>
              </Box>

              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  bttype="primary"
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  Afslut
                </Button>
              </Box>
            </>
          )}

          {!isMutating && !userCreated && (
            <>
              <Box>
                <Typography variant="h5">Der skete en fejl under oprettelsen</Typography>
              </Box>
              <Box>
                <>
                  <Typography>{mutationError}</Typography>
                </>
              </Box>

              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  bttype="primary"
                  onClick={() => {
                    backToRegister();
                    setPage('decideOrgOrNoOrg');
                  }}
                >
                  Gå tilbage
                </Button>
              </Box>
            </>
          )}

          {isMutating && (
            <>
              <LinearLoading width="large" height="large" color="primary" />
            </>
          )}
        </>
      </Box>
    </Fade>
  );
};

export default UserCreated;
