import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';

import {apiClient} from '~/api/apiClient';
import {authStore} from '~/state/store';
import {Project, ProjectCreationType} from '~/types/zod_objects';

import {useOrgRole} from './permissions';

const useProjects = () => {
  const superUser = authStore((state) => state.superUser);
  return useQuery({
    queryKey: ['getProjects'],
    queryFn: async () => {
      const {data} = await apiClient.get<Project[]>(`/admin/projects`);
      return data;
    },
    enabled: superUser,
    refetchOnWindowFocus: false,
  });
};

const useOrgProjects = (org_id: string) => {
  const superUser = authStore((state) => state.superUser);
  const role = useOrgRole(org_id ?? '');
  const editPermissions = role === 'ADMIN' || superUser;
  return useQuery({
    queryKey: ['getOrgProjects', org_id],
    queryFn: async () => {
      const {data} = await apiClient.get<Project[]>(`/admin/org_projects/${org_id}`);
      return data;
    },
    enabled: editPermissions,
    refetchOnWindowFocus: false,
  });
};

const createProjectMutation = {
  mutationKey: ['createProject'],
  mutationFn: async (project: ProjectCreationType) => {
    const {data} = await apiClient.post('/admin/project', project);
    return data;
  },
};

const useCreateProject = () => {
  return useMutation({
    ...createProjectMutation,
    onError: (e: AxiosError<{detail: string}>) => {
      toast.error(e?.response?.data?.detail);
    },
    onSuccess: () => {
      toast.success('Projekt oprettet!');
    },
  });
};

export {useProjects, useOrgProjects, useCreateProject};
