import {Box, Typography, FormGroup, FormControlLabel, Checkbox, Fade, Divider} from '@mui/material';
import {useEffect, useState} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';

import Button from '~/components/Button';
import LinearLoading from '~/components/LineLoader';
import {useUpdateEmailPreferences} from '~/hooks/queries/users';
import useBreakpoints from '~/hooks/useBreakpoints';
import {appBarHeight} from '~/types/consts';
import {EmailPreferencesType} from '~/types/zod_objects';
// import CalypsoLogo from '~/watsonc.svg?react';

interface EmailPreferencesFormProps {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  email: string;
}

export default function EmailPreferencesForm({setPage, email}: EmailPreferencesFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const {control, handleSubmit, setValue} = useFormContext<EmailPreferencesType>();
  const patchMutation = useUpdateEmailPreferences();
  const onSubmitHandler = (values: EmailPreferencesType) => {
    setIsLoading(true);
    const payload = {
      ...values,
      email: email || '',
    };
    patchMutation.mutate(payload, {
      onSuccess: () => {
        setIsLoading(false);
        setPage(1);
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  };

  const {isMobile} = useBreakpoints();
  const watchedValues = useWatch({control});

  const checkAllBoxes = (value: boolean) => {
    setValue('software', value);
    setValue('vandloeb_overfladevand', value);
    setValue('grundvand', value);
    setValue('natur', value);
    setValue('produkter_vandloeb', value);
    setValue('produkter_grundvand', value);
    setValue('dambrug', value);
  };

  useEffect(() => {
    const trueExists = Object.values(watchedValues).some((value) => value === true);
    const falseExists = Object.values(watchedValues).some((value) => value === false);
    if (trueExists && falseExists) {
      setIsIndeterminate(true);
    } else {
      setIsIndeterminate(false);
    }
  }, [watchedValues]);

  return (
    <Fade in={true}>
      <Box
        sx={{
          height: '100%',
          mb: 4,
          marginTop: appBarHeight,
          ...(!isMobile && {
            borderRadius: 4,
            maxWidth: '500px',
            padding: isMobile ? '16px' : '48px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }),
        }}
      >
        {/* <CalypsoLogo height={isMobile ? '50px' : undefined} /> */}
        <Typography variant="h2" textAlign={'center'}>
          Velkommen! 🎉
        </Typography>
        <Typography variant="body2" mb={2}>
          Tak for oprettelsen! Vi er glade for at du har lyst til at være en del af vores
          Calypso-univers. Vi har rigtig mange ting vi gerne vil fortælle om, så vi håber derfor du
          har lyst til at fortælle os hvilke emner du gerne vil holdes opdateret indenfor.
        </Typography>
        <Typography fontWeight={900} variant="body2" mb={0}>
          Vælg dine interesser:
        </Typography>
        <FormControlLabel
          sx={{height: '32px'}}
          control={
            <Checkbox
              onChange={(e) => checkAllBoxes(e.target.checked)}
              indeterminate={isIndeterminate}
            />
          }
          label={<Typography variant="body2">Vælg alle</Typography>}
        />

        <Box>
          <Box sx={{display: 'flex', height: '100%'}}>
            <Divider orientation="vertical" sx={{width: 10}} flexItem />
            <Box component={'form'} noValidate autoComplete="off" ml={2}>
              <FormGroup>
                <Typography
                  variant="body2"
                  fontSize={14}
                  color={'rgb(102, 102, 102)'}
                  fontWeight={900}
                  mb={0}
                >
                  Calypso
                </Typography>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="software"
                      render={({field: {value, ...field}}) => (
                        <Checkbox {...field} checked={!!value} />
                      )}
                    />
                  }
                  sx={{height: '25px'}}
                  label={
                    <Typography variant="body2">
                      {isMobile
                        ? 'Softwareopdateringer, Webinarer og lign.'
                        : 'Softwareopdateringer, Calypso Webinarer og lign.'}
                    </Typography>
                  }
                />
              </FormGroup>
              <FormGroup style={{marginTop: 16}}>
                <Typography
                  variant="body2"
                  fontSize={14}
                  color={'rgb(102, 102, 102)'}
                  fontWeight={900}
                  mb={0}
                >
                  Rådgivning
                </Typography>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="vandloeb_overfladevand"
                      render={({field: {value, ...field}}) => (
                        <Checkbox {...field} checked={!!value} />
                      )}
                    />
                  }
                  sx={{height: '25px'}}
                  label={<Typography variant="body2">Vandløb/overfladevand/klima</Typography>}
                />
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="grundvand"
                      render={({field: {value, ...field}}) => (
                        <Checkbox {...field} checked={!!value} />
                      )}
                    />
                  }
                  sx={{height: '25px'}}
                  label={<Typography variant="body2">Grundvand</Typography>}
                />
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="natur"
                      render={({field: {value, ...field}}) => (
                        <Checkbox {...field} checked={!!value} />
                      )}
                    />
                  }
                  sx={{height: '25px'}}
                  label={<Typography variant="body2">Natur</Typography>}
                />
              </FormGroup>
              <FormGroup style={{marginTop: 16}}>
                <Typography
                  variant="body2"
                  fontSize={14}
                  color={'rgb(102, 102, 102)'}
                  fontWeight={900}
                  mb={0}
                >
                  Online dataindsamling
                </Typography>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="produkter_vandloeb"
                      render={({field: {value, ...field}}) => (
                        <Checkbox {...field} checked={!!value} />
                      )}
                    />
                  }
                  sx={{height: '25px'}}
                  label={
                    <Typography variant="body2">Produkter til vandløb og overfladevand</Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="produkter_grundvand"
                      render={({field: {value, ...field}}) => (
                        <Checkbox {...field} checked={!!value} />
                      )}
                    />
                  }
                  sx={{height: '25px'}}
                  label={<Typography variant="body2">Produkter til grundvand</Typography>}
                />
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="dambrug"
                      render={({field: {value, ...field}}) => (
                        <Checkbox {...field} checked={!!value} />
                      )}
                    />
                  }
                  sx={{height: '25px'}}
                  label={<Typography variant="body2">Produkter til dambrug</Typography>}
                />
              </FormGroup>
            </Box>
          </Box>

          <Box sx={{display: 'flex', justifyContent: 'end', gap: 1, alignItems: 'center'}}>
            <Button onClick={() => setPage(1)} bttype="tertiary">
              Afvis
            </Button>

            {!isLoading ? (
              <Button onClick={handleSubmit(onSubmitHandler)} bttype="primary">
                Tilmeld{' '}
                <Box component={'span'} color="secondary.main">
                  *
                </Box>
              </Button>
            ) : (
              <LinearLoading width="medium" height="medium" color="primary" />
            )}
          </Box>
          <Typography variant="body2" mt={2} justifySelf={'end'}>
            <Box component={'span'} color={'secondary.main'}>
              *
            </Box>{' '}
            Du accepterer følgende{' '}
            <a href="https://www.watsonc.dk/vilkaar/#nyhedsbrev" target="_blank" rel="noreferrer">
              vilkår
            </a>{' '}
            ved at tilmelde dig:{' '}
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
}
