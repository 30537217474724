import {ArrowForward, ArrowBack} from '@mui/icons-material';
import {Fade, Box, Typography} from '@mui/material';
import React from 'react';

import AdministrerLicens from '~/assets/AdministrerLicens.png';
import Button from '~/components/Button';

interface SecondIndividualLicenseProps {
  page:
    | 'licenseManagement'
    | 'firstIndividualLicense'
    | 'secondIndividualLicense'
    | 'companyLicense';
  setPage: React.Dispatch<
    React.SetStateAction<
      'licenseManagement' | 'firstIndividualLicense' | 'secondIndividualLicense' | 'companyLicense'
    >
  >;
}

const SecondIndividualLicense = ({setPage, page}: SecondIndividualLicenseProps) => {
  return (
    <Fade in={page === 'secondIndividualLicense'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography mb={3} textAlign={'center'} variant="h5">
            <b>Individuel Licensstyring</b>
          </Typography>
        </Box>

        <Typography mb={3}>Herefter er brugeren tildelt premium-licensen.</Typography>
        <Box
          mb={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <img style={{width: '500px'}} src={AdministrerLicens} alt="Tilføj Licens" />
        </Box>
        <Typography mb={3}>
          Brugeren kan fjernes fra licensen igen, eller skiftes ud med en anden
        </Typography>
        <Typography mb={3}>
          Ved faktureringsdatoen bliver alle premium-licenser, der er tildelt en bruger, fornyet.{' '}
        </Typography>
        <Box sx={{display: 'flex', justifyContent: 'center', mt: 0}}>
          <Box sx={{display: 'flex', gap: 2}}>
            <Button
              startIcon={<ArrowBack />}
              onClick={() => setPage('firstIndividualLicense')}
              bttype="primary"
            >
              Tilbage
            </Button>
            <Button
              endIcon={<ArrowForward />}
              onClick={() => setPage('companyLicense')}
              bttype="primary"
            >
              Fortsæt
            </Button>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default SecondIndividualLicense;
