import {useQuery} from '@tanstack/react-query';

import {apiClient} from '~/api/apiClient';
import {authStore} from '~/state/store';
import {Unit} from '~/types/zod_objects';

import {useOrgRole} from './permissions';

const useUnits = () => {
  const superUser = authStore((state) => state.superUser);
  return useQuery({
    queryKey: ['getUnits'],
    queryFn: async () => {
      const {data} = await apiClient.get<Unit[]>(`/admin/units`);
      return data;
    },
    enabled: superUser,
    refetchOnWindowFocus: false,
  });
};

const useGroupedUnits = (org_id: string | null) => {
  const superUser = authStore((state) => state.superUser);
  const role = useOrgRole(org_id ?? '');
  const editPermissions = role === 'ADMIN' || superUser;
  return useQuery({
    queryKey: ['getGroupedUnits', org_id],
    queryFn: async () => {
      const {data} = await apiClient.get<Unit[]>(`/admin/units/grouped/${org_id}`);
      return data;
    },
    enabled: editPermissions,
    refetchOnWindowFocus: false,
  });
};

const useOrgUnits = (org_id: string) => {
  const superUser = authStore((state) => state.superUser);
  const role = useOrgRole(org_id ?? '');
  const editPermissions = role === 'ADMIN' || superUser;
  return useQuery({
    queryKey: ['getOrgUnits', org_id],
    queryFn: async () => {
      const {data} = await apiClient.get<Unit[]>(`/admin/org_units/${org_id}`);
      return data;
    },
    enabled: editPermissions,
    refetchOnWindowFocus: false,
  });
};

export {useGroupedUnits, useUnits, useOrgUnits};
