import {useMutation, useQuery} from '@tanstack/react-query';

import {apiClient} from '~/api/apiClient';
import {Permissions, PermissionsMutation} from '~/types/zod_objects';

const usePermissions = () =>
  useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const {data} = await apiClient.get<Permissions>(`/admin/my-permissions`);
      return data;
    },
    refetchOnWindowFocus: false,
  });

const usePermissionMutation = () => {
  return useMutation({
    mutationFn: async (payload: PermissionsMutation) => {
      const {data} = await apiClient.post(
        `/admin/users/${payload.user_id}/permissions`,
        payload.permissions
      );
      return data;
    },
  });
};

const useOrgRole = (org_id: string | undefined) => {
  const {data} = usePermissions();
  const foundSuperUser = data?.org_roles?.find((role) => role.role == 'SUPERUSER');
  if (foundSuperUser) {
    return 'SUPERUSER';
  } else {
    const orgRole = data?.org_roles.find((role) => role.org_id === org_id);
    return orgRole?.role;
  }
};

export {usePermissions, useOrgRole, usePermissionMutation};
