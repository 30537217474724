import {Paper, Divider, Box, Typography} from '@mui/material';

import {useProjects} from '~/hooks/queries/projects';
import useBreakpoints from '~/hooks/useBreakpoints';
import {TableTypes} from '~/hooks/useTable';
import ProjectsTable from '~/tables/ProjectsTable';

const AllProjectsTable = () => {
  const {data} = useProjects();
  const {isMobile} = useBreakpoints();
  return (
    <Paper elevation={4} sx={{justifyContent: 'center', margin: 0, padding: 0}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: isMobile ? 0 : 2,
          paddingY: isMobile ? 1 : 2,
          paddingBottom: isMobile ? 1 : 0,
        }}
      >
        <Typography variant="h5" fontWeight={900}>
          Projekter
        </Typography>
      </Box>{' '}
      <Divider
        sx={{
          width: '100%',
          borderBottomWidth: '2px',
          borderBottomColor: '#00786d',
          marginTop: isMobile ? 0 : '1em',
        }}
      />
      <ProjectsTable data={data} table_type={TableTypes.SCROLLING} long={true} />
    </Paper>
  );
};

export default AllProjectsTable;
