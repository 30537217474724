import {Fade, Box, Typography} from '@mui/material';
import React from 'react';

import Button from 'src/components/Button';
import LinearLoading from '~/components/LineLoader';
import type {CvrData} from '~/api/functions';

interface ConfirmOrganizationCreationProps {
  page: string;
  setPage: React.Dispatch<
    React.SetStateAction<'decideOrgOrNoOrg' | 'enterCVR' | 'confirmOrg' | 'creationConfirmed'>
  >;
  cvrData: CvrData | null;
  setCvrData: React.Dispatch<React.SetStateAction<CvrData | null>>;
  finishCreation: () => void;
}

const ConfirmOrganizationCreation = ({
  page,
  setPage,
  cvrData,
  setCvrData,
  finishCreation,
}: ConfirmOrganizationCreationProps) => {
  return (
    <Fade in={page == 'confirmOrg'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box>
          <Typography variant="h5">Opret organisation</Typography>
        </Box>
        <Box>
          {cvrData !== null ? (
            <>
              <Typography>Vi har fundet følgende information om virksomheden:</Typography>
              <Box mt={3}>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                  <Typography variant="subtitle2" fontWeight={900}>
                    Virksomhedsnavn:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{textAlign: 'left', color: 'primary.main'}}
                    fontWeight={100}
                  >
                    {cvrData.name}
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                  <Typography variant="subtitle2" fontWeight={900}>
                    Adresse:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{textAlign: 'left', color: 'primary.main'}}
                    fontWeight={100}
                  >
                    {' '}
                    {cvrData.address}, {cvrData.city}, {cvrData.zip}
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                  <Typography variant="subtitle2" fontWeight={900}>
                    Land:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{textAlign: 'left', color: 'primary.main'}}
                    fontWeight={100}
                  >
                    {' '}
                    {cvrData.country}
                  </Typography>
                </Box>
                <Typography mt={3}>Vil du oprette den organisation i Calypso?</Typography>
              </Box>
            </>
          ) : (
            <Box
              mt={0}
              mb={20}
              sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
            >
              <Typography mb={3}>Leder efter virksomhedsinformation...</Typography>

              <LinearLoading width="large" height="large" color="primary" />
            </Box>
          )}
        </Box>
        {cvrData !== null && (
          <Box sx={{display: 'flex', justifyContent: 'end', gap: 2}}>
            <Button
              onClick={() => {
                setPage('enterCVR');
                setCvrData(null);
              }}
              bttype="tertiary"
            >
              Gå tilbage
            </Button>
            <Button bttype="primary" onClick={() => finishCreation()}>
              Opret organisation
            </Button>
          </Box>
        )}
      </Box>
    </Fade>
  );
};

export default ConfirmOrganizationCreation;
