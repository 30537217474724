import {create} from 'zustand';
import {createJSONStorage, devtools, persist} from 'zustand/middleware';

// create authstore type
// create role enum

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
  PENDING = 'PENDING',
  SUPERUSER = 'SUPERUSER',
}

type AuthState = {
  authenticated: boolean;
  user_id: string | null;
  org_id: string | null;
  loginExpired: boolean;
  boreholeAccess: boolean;
  iotAccess: boolean;
  adminAccess: boolean;
  superUser: boolean;
  properties: any;
  resetState: () => void;
  setAuthenticated: (authenticated: boolean) => void;
  setAuthorization: (user: any) => void;
  setLoginExpired: (loginexpired: boolean) => void;
};

const authInitialState = {
  authenticated: false,
  user_id: null,
  org_id: null,
  loginExpired: false,
  boreholeAccess: false,
  iotAccess: false,
  adminAccess: false,
  superUser: false,
  properties: {},
};

export const authStore = create<AuthState>()(
  persist(
    devtools((set) => ({
      ...authInitialState,
      resetState: () => {
        set(authInitialState, false, 'resetState');
      },
      setAuthenticated: (authenticated) =>
        set(
          {
            authenticated: authenticated,
          },
          false,
          'setAuthenticated'
        ),
      setAuthorization: (user) => {
        set(
          {
            properties: user.properties,
            boreholeAccess: user.boreholeAccess,
            user_id: user.user_id,
            org_id: user.org_id,
            iotAccess: user.iotAccess,
            adminAccess: user.adminAccess,
            superUser: user.superUser,
          },
          false,
          'setAuthorization'
        );
      },
      setLoginExpired: (loginexpired) =>
        set(
          {
            loginExpired: loginexpired,
          },
          false,
          'setLoginExpired'
        ),
    })),
    {
      name: 'calypso-auth-storage', // name of item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used
    }
  )
);
