// A hook that returns whether the current screen size is mobile, tablet or desktop.

// eslint-disable-next-line prettier/prettier
import {useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function useBreakpoints() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const isTablet = useMediaQuery(theme.breakpoints.between('tablet', 'laptop'));
  const isDrawerOpen = !useMediaQuery(theme.breakpoints.down('md'));
  const isLaptop = useMediaQuery(theme.breakpoints.up('laptop'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const isShortHeight = useMediaQuery('(max-height: 1035px)');
  const isTouch = useMediaQuery(theme.breakpoints.down('laptop'));

  // eslint-disable-next-line prettier/prettier
  return {isMobile, isTablet, isLaptop, isTouch, isDesktop, isDrawerOpen, isShortHeight};
}
