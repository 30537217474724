import {Paper, Divider, Box, Typography} from '@mui/material';
import {useLocation, useParams} from 'react-router-dom';

import {useGroupedUnits} from '~/hooks/queries/units';
import useBreakpoints from '~/hooks/useBreakpoints';
import {TableTypes} from '~/hooks/useTable';
import {authStore} from '~/state/store';
import UnitsTable from '~/tables/UnitsTable';

const AllUnitsPage = () => {
  // const {data, isLoading} = useUnits();

  const {orgId} = useParams();
  const userOrgId = authStore((state) => state.org_id);
  let org_id = orgId || userOrgId || 'all';
  if (useLocation().pathname === '/units') {
    org_id = 'all';
  }
  const {data: groupedUnits} = useGroupedUnits(org_id);
  const {isMobile} = useBreakpoints();

  return (
    <Paper elevation={4} sx={{justifyContent: 'center', margin: 0, padding: 0}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: isMobile ? 0 : 2,
          paddingY: isMobile ? 1 : 2,
          paddingBottom: isMobile ? 1 : 0,
        }}
      >
        <Typography variant="h5" fontWeight={900}>
          Udstyrsenheder
        </Typography>
      </Box>{' '}
      <Divider
        sx={{
          width: '100%',
          borderBottomWidth: '2px',
          borderBottomColor: '#00786d',
          marginTop: isMobile ? 0 : '1em',
        }}
      />
      <UnitsTable data={groupedUnits} table_type={TableTypes.TABLE} long={true} />;
    </Paper>
  );
};

export default AllUnitsPage;
