import {Drawer, Toolbar} from '@mui/material';
import * as React from 'react';

import {AuthenticatedRoutes} from '~/components/AuthenticatedRoutes';
import {drawerWidth} from '~/types/consts';

import useBreakpoints from '../hooks/useBreakpoints';

interface SideDrawerProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideDrawer = ({open, setOpen}: SideDrawerProps) => {
  const {isDesktop} = useBreakpoints();

  return (
    <Drawer
      variant={!isDesktop ? 'temporary' : 'permanent'}
      open={!isDesktop ? open : true}
      onClose={() => setOpen(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'primary.main',
          color: 'white',
        },
      }}
    >
      <Toolbar />
      <AuthenticatedRoutes setOpenDrawer={setOpen} />
    </Drawer>
  );
};

export default SideDrawer;
