import {Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {AxiosError} from 'axios';
import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import Button from '~/components/Button';
import {useForgotPassword} from '~/hooks/queries/useLogin';

interface ForgotPasswordDialogProps {
  forgetOpen: boolean;
  setForgetOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPasswordDialog = ({forgetOpen, setForgetOpen}: ForgotPasswordDialogProps) => {
  const [inputError, setInputError] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const [searchParams] = useSearchParams();

  const redirectKey = encodeURIComponent(searchParams.get('redirect') || '');

  const mutation = useForgotPassword();

  const closeDialog = () => {
    setEmailInput('');
    setInputError('');
    setForgetOpen(false);
    setTimeout(() => {
      setResetSuccess(false);
    }, 500);
  };

  const handleResetPassword = async () => {
    if (!emailInput) {
      setInputError('E-mail skal udfyldes');
      return;
    }

    mutation.mutate(
      {email: emailInput, redirect: `?redirect=${redirectKey}` || ''},
      {
        onSuccess: () => {
          setResetSuccess(true);
        },
        onError: (error: Error) => {
          const newError = error as AxiosError;
          setInputError((newError?.response?.data as {detail?: string}).detail || '');
          setResetSuccess(false);
        },
      }
    );
  };

  return (
    <Dialog
      open={forgetOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
          paddingBottom: 0,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5">Nulstil kodeord</Typography>
      </DialogTitle>
      <DialogContent>
        {resetSuccess === true ? (
          <Typography>
            En E-mail er er blevet sendt med instruktioner for hvordan du nulstiller dit kodeord.
          </Typography>
        ) : (
          <>
            <Typography>Nulstil dit kodeord ved at indtaste din E-mail adresse</Typography>
            <TextField
              margin="dense"
              id="passReset"
              label="E-mail addresse"
              type="email"
              onChange={(e) => setEmailInput(e.target.value)}
              fullWidth
              error={inputError != ''}
              helperText={inputError}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} bttype="tertiary">
          Annuller
        </Button>
        {!resetSuccess && (
          <Button onClick={handleResetPassword} bttype="primary">
            Bekræft
          </Button>
        )}
        {resetSuccess && (
          <Button onClick={() => closeDialog()} bttype="primary">
            Gå til log ind
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
