import {Box, useMediaQuery} from '@mui/material';
import React from 'react';
import {Routes, Route} from 'react-router-dom';

import Login from '~/pages/login/Login';
import Register from '~/pages/login/Register';
import VerifiedAndEmailPage from '~/pages/login/verifiedPages/VerifiedAndEmailPage';

import {appBarHeight} from './types/consts';

export default function UnAuntenticatedApp() {
  const center = useMediaQuery('(min-height: 550px)');

  return (
    <Box
      height={center ? '100vh' : ''}
      mt={!center ? `calc(${appBarHeight} + 32px)` : 0}
      sx={{
        ...(center && {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
      }}
    >
      <Routes>
        <Route path="/verified" element={<VerifiedAndEmailPage />} />
        <Route path="/reset-password" element={<Login showResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </Box>
  );
}
