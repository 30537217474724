import {zodResolver} from '@hookform/resolvers/zod';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@mui/material';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useLocation} from 'react-router-dom';

import {useOrgs} from '~/hooks/queries/organizations';
import {useOrgRole} from '~/hooks/queries/permissions';
import {useCreateProject} from '~/hooks/queries/projects';
import {authStore} from '~/state/store';
import {ProjectCreationSchema, ProjectCreationType} from '~/types/zod_objects';

import Button from '../Button';
import FormInput from '../FormInput';
import FormInputCheckbox from '../FormInputCheckbox';
import OrganizationDropdown from '../OrganizationDropdown';

type CreateProjectDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateProjectDialog = ({open, setOpen}: CreateProjectDialogProps) => {
  const {data: organisationsData, isLoading} = useOrgs();

  const user = authStore();

  const viewedOrgParams = useLocation().pathname.split('/');
  let org_id = '';
  if (viewedOrgParams[1] !== 'projects') {
    const viewedOrgId = viewedOrgParams[2];
    if (!viewedOrgId) {
      org_id = user.org_id || '';
    } else {
      org_id = viewedOrgId;
    }
  }
  const viewedOrg = organisationsData?.find((org) => org.id === org_id);
  const editRights = useOrgRole(user.org_id || '');
  const createProjectMutation = useCreateProject();

  const formMethods = useForm<ProjectCreationType>({
    resolver: zodResolver(ProjectCreationSchema),
    defaultValues: {
      is_customer_service: false,
      organization_id: org_id,
      customer_name: viewedOrg?.name || '',
      project_no: '',
      project_title: '',
      project_info: '',
    },
  });
  const {control, reset, handleSubmit, setValue} = formMethods;

  const organizationChange = useWatch({
    control,
    name: 'organization_id',
  });
  if (organizationChange) {
    setValue(
      'customer_name',
      organisationsData?.find((org) => org.id === organizationChange)?.name || ''
    );
  }

  const handleCreate = (values: ProjectCreationType) => {
    createProjectMutation.mutate(values, {
      onSuccess: () => {
        reset();
        setOpen(false);
      },
    });
  };

  if (!editRights) {
    return null;
  }

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={(reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpen(false);
      }}
      scroll="paper"
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
          paddingBottom: 1,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
      }}
    >
      <DialogTitle fontWeight={'bold'}>Opret nyt projekt</DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <DialogContentText mb={2}>Nedenstående felter kan redigeres</DialogContentText>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              <FormInput
                name="project_no"
                label="Projekt nr."
                placeholder="f.eks. 22.0023"
                fullWidth
                sx={{mb: 2}}
              />
              <FormInput
                name="project_title"
                label="Projekt Titel"
                placeholder="f.eks. Hovgaard Sø"
                fullWidth
                sx={{mb: 2}}
              />
              <FormInput
                name="project_info"
                label="Projekt info"
                placeholder="Faktureringsmæssige noter f.eks. ATT"
                fullWidth
                sx={{mb: 2}}
              />
              {viewedOrgParams[1] === 'projects' ? (
                <OrganizationDropdown
                  name="organization_id"
                  organizations={organisationsData}
                  control={control}
                />
              ) : (
                <Typography variant="body2" sx={{mb: 2}}>
                  Projektet vil blive oprettet under
                  {' ' + viewedOrg?.name}
                </Typography>
              )}

              <FormInputCheckbox name="is_customer_service" label="Serviceres af kunde" />
            </>
          )}
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{paddingRight: 3}}>
        <Button
          bttype="tertiary"
          onClick={() => {
            reset();
            setOpen(false);
          }}
        >
          Annuller
        </Button>
        <Button bttype="primary" onClick={handleSubmit(handleCreate)}>
          Opret
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProjectDialog;
