import {Box, Typography, Container, Fade} from '@mui/material';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

export default function VerifiedCountdown({redirectKey}: {redirectKey: string | null}) {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter <= 0) {
        clearInterval(interval);
        if (redirectKey) {
          window.location.href = redirectKey;
        } else {
          navigate('/login');
        }
      } else {
        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [counter, navigate, redirectKey]);
  return (
    <Fade in={true}>
      <Box
        sx={{
          borderRadius: 4,
          padding: '32px',
          maxWidth: 'sm',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            alignSelf: 'center',
          }}
        >
          <Typography mb={2} variant="h4">
            Velkommen!
          </Typography>
        </Box>

        <Container fixed maxWidth="sm">
          <Typography
            mb={2}
            style={{
              textAlign: 'center',
              alignSelf: 'center',
            }}
          >
            {counter == 0
              ? 'Du vil blive sendt tilbage til login siden nu'
              : 'Du vil blive sendt tilbage til login siden om ' + counter + ' sekunder'}
          </Typography>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <Box
              mt={1}
              mb={1}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 2,
              }}
            ></Box>
          </Box>
        </Container>
      </Box>
    </Fade>
  );
}
