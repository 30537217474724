import {LinearProgress, LinearProgressProps} from '@mui/material';

interface LinearLoadingProps extends Omit<LinearProgressProps, 'variant'> {
  width?: 'small' | 'medium' | 'large' | 'x-small';
  height?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'error' | 'secondary' | 'info' | 'success' | 'warning' | 'inherit';
}

const LinearLoading = ({width, height, color, ...props}: LinearLoadingProps) => {
  const sx = {
    borderRadius: 9999,
    height: 8,
    width: 75,
    mx: 0,
    '.MuiLinearProgress-bar': {
      backgroundColor: 'white',
    },
  };

  switch (width) {
    case 'x-small':
      sx.width = 31;
      break;
    case 'small':
      sx.width = 50;
      break;
    case 'medium':
      sx.width = 75;
      break;
    case 'large':
      sx.width = 100;
      break;
  }

  switch (color) {
    case 'info':
      sx['.MuiLinearProgress-bar'].backgroundColor = 'info.main';
      break;
    case 'success':
      sx['.MuiLinearProgress-bar'].backgroundColor = 'primary.main';
      break;
    case 'warning':
      sx['.MuiLinearProgress-bar'].backgroundColor = 'warning.main';
      break;
    case 'error':
      sx['.MuiLinearProgress-bar'].backgroundColor = 'error.main';
      break;
    case 'secondary':
      sx['.MuiLinearProgress-bar'].backgroundColor = 'secondary.main';
      break;
    case 'primary':
      sx['.MuiLinearProgress-bar'].backgroundColor = 'primary.main';
      break;
  }

  switch (height) {
    case 'small':
      sx.height = 4;
      break;
    case 'medium':
      sx.height = 8;
      break;
      break;
    case 'large':
      sx.height = 12;
      break;
  }

  return <LinearProgress {...props} variant="indeterminate" sx={sx} />;
};

export default LinearLoading;
