import {Box} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {useState} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

import Navbar from '~/components/NavBar';
import SideDrawer from '~/components/SideDrawer';
import useBreakpoints from '~/hooks/useBreakpoints';
import AllOrganizationsPage from '~/pages/organizations/AllOrganizationsPage';
import EditOrganizationPage from '~/pages/organizations/EditOrganizationPage';
import SingleOrganizationPage from '~/pages/organizations/SingleOrganizationPage';
import AllProjectsPage from '~/pages/projects/AllProjectsPage';
import AllUnitsPage from '~/pages/units/AllUnitsPage';
import AllUsersPage from '~/pages/users/AllUsersPage';
import UserDetailsPage from '~/pages/users/UserDetailsPage';
import {authStore} from '~/state/store';
import {drawerWidth} from '~/types/consts';

import NotFound from './NotFound';

const ManagementPage = () => {
  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const {org_id, superUser} = authStore();
  const {isMobile, isDesktop} = useBreakpoints();
  if (!org_id) {
    return (
      <Box display="flex">
        <CssBaseline />
        <Navbar setOpenSideDrawer={setOpenSideDrawer} />
        <Box component="main" sx={{flexGrow: 1, pt: '80px'}}>
          <Routes>
            <Route path="/" element={<UserDetailsPage isProfile />} />
            <Route path="/profile" element={<UserDetailsPage isProfile />} />
            <Route path="*" element={<UserDetailsPage />} />
          </Routes>
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex">
      <CssBaseline />
      <Navbar setOpenSideDrawer={setOpenSideDrawer} />
      {superUser && <SideDrawer open={openSideDrawer} setOpen={setOpenSideDrawer} />}
      <Box
        component="main"
        className="main"
        sx={{
          flexGrow: 1,
          pt: isMobile ? '68px' : '75px',
          maxWidth: isDesktop && superUser ? `calc(98% - ${drawerWidth}px)` : '98%',
          minWidth: `calc(98% - ${drawerWidth}px)`,
          mx: 'auto',
        }}
      >
        <Routes>
          <Route path="/" element={<SingleOrganizationPage key="own" />} />
          <Route path="/organizations" element={<AllOrganizationsPage />} />
          <Route path="/organizations/:orgId" element={<SingleOrganizationPage key="other" />} />
          <Route path="/organizations/:orgId/edit" element={<EditOrganizationPage />} />
          <Route path="/users" element={<AllUsersPage />} />
          <Route path="/profile" element={<UserDetailsPage isProfile />} />
          <Route path="/users/:userId" element={<UserDetailsPage />} />
          <Route path="/projects" element={<AllProjectsPage />} />
          <Route path="/units" element={<AllUnitsPage />} />
          <Route path="/login" element={<Navigate to="/profile" />} />
          <Route path="/register" element={<Navigate to="/profile" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default ManagementPage;
